<template>
  <!-- table -->
  <div class="dashboard_state_panel">
    <b-container fluid class="state-container">
      <!-- jyp 터널 탭 -->
      <b-row>
        <div style="height: 20%; width: 100%">
          <div id="tunnelMap" class="rtd_div"></div>
        </div>
      </b-row>

      <!-- jyp 터널 탭 이름 -->
      <!-- <b-row class="text-center" style="height: 30%;">
        <div v-for="tunnel in tunnelLocationList" v-bind:key="tunnel.location" class="tunnel_title" :id="'tunnel_id_'+tunnel.idx" :style="'flex: '+tunnel.flex+';'">{{ tunnel.location }}</div>
			</b-row> -->

      <b-row class="text-left" style="height: 100%">
        <div v-show="dbsStateContentTf" class="state_div_small state_content">
          <div class="state_div_top">
            <img
              src="resources/img/icons/dashboard/effect/BundangUpside.svg"
              style="width: 1.4vw; margin-right: 0.3vw; margin-left: 7px"
            />
            <span class="state_title">{{ $t("label.L0019") }}</span>
            <!-- <img src="resources/img/icons/dashboard/more_horiz-24px.svg" style="width: 1vw;" class="float_right"/>					-->
          </div>
          <div class="state_div_bot" style="display: inline-flex">
            <div style="width: 60%; height: 100%">
              <!-- <animated-number :value="avr_speed_s"  :duration="number_duration" :formatValue="formatToSpeed" class="state_font_big" :style="'color :'+getColor(avr_speed_s)" /> -->
              <span
                class="state_font_size_ec"
                :style="'color :' + getColor(currentAvrSpeed.s.avrSpeed)"
              >
                {{ formatToSpeed(currentAvrSpeed.s.avrSpeed) }}
              </span>
              <span class="font_size_small">km/h</span>
            </div>
            <div style="width: 55%; height: 100%">
              <img
                src="resources/img/icons/dashboard/upward_arrow.svg"
                v-show="currentAvrSpeed.s.arrowDirection"
                style="width: 0.5vw; margin-top: -0.5vw"
              />
              <img
                src="resources/img/icons/dashboard/downward_arrow.svg"
                v-show="!currentAvrSpeed.s.arrowDirection"
                style="width: 0.5vw; margin-top: -0.5vw"
              />
              <!-- <animated-number :value="compare_speed_s"  :duration="number_duration" :formatValue="formatToSpeed" class="state_font_small" /> -->
              <span class="state_font_size_ec">
                {{ formatToSpeed(currentAvrSpeed.s.compareSpeed) }}
              </span>
              <span class="font_size_small">km/h</span>
            </div>
          </div>
        </div>
        <div v-show="dbsStateContentTf" class="state_space"></div>

        <div class="state_div_small state_content">
          <div class="state_div_top">
            <img
              src="resources/img/icons/dashboard/effect/SeoulDownside.svg"
              style="width: 1.4vw; margin-right: 0.3vw; margin-left: 7px"
            />
            <span class="state_title">{{ $t("label.L0020") }}</span>
            <!-- <img src="resources/img/icons/dashboard/more_horiz-24px.svg" style="width: 1vw;" class="float_right"/> -->
          </div>
          <div class="state_div_bot" style="display: inline-flex">
            <div style="width: 60%; height: 100%">
              <!-- <animated-number :value="avr_speed_e"  :duration="number_duration" :formatValue="formatToSpeed" class="state_font_big" :style="'color :'+getColor(avr_speed_e)" /> -->
              <span
                class="state_font_size_ec"
                :style="'color :' + getColor(currentAvrSpeed.e.avrSpeed)"
              >
                {{ formatToSpeed(currentAvrSpeed.e.avrSpeed) }}
              </span>
              <span class="font_size_small">km/h</span>
            </div>
            <div style="width: 55%; height: 100%">
              <img
                src="resources/img/icons/dashboard/upward_arrow.svg"
                v-show="currentAvrSpeed.e.arrowDirection"
                style="width: 0.5vw; margin-top: -0.5vw"
              />
              <img
                src="resources/img/icons/dashboard/downward_arrow.svg"
                v-show="!currentAvrSpeed.e.arrowDirection"
                style="width: 0.5vw; margin-top: -0.5vw"
              />
              <!-- <animated-number :value="compare_speed_e"  :duration="number_duration" :formatValue="formatToSpeed" class="state_font_small" /> -->
              <span class="state_font_size_ec">
                {{ formatToSpeed(currentAvrSpeed.e.compareSpeed) }}
              </span>
              <span class="font_size_small">km/h</span>
            </div>
          </div>
        </div>
        <div class="state_space"></div>
        <!-- <div class="state_div_big state_content" style="display: inline-flex"> -->
        <div :class="state_div_big" style="display: inline-flex">
          <div class="state_div_top_top" style="height: 100%; width: 17%">
            <div class="state_div_top">
              <img
                src="resources/img/icons/dashboard/effect/All.svg"
                style="width: 1.5vw; margin-right: 0.1vw"
              />
              <span class="state_title">&nbsp; {{ $t("label.L0021") }}</span>
            </div>
            <div class="state_div_bot_cl">
              <!-- <img class="state_ball" src="resources/img/icons/dashboard/effect/yellow_ball.svg" v-show="total_unconfirmed_count == 0"/> -->
              <span class="state_font_size_ec">
                {{ total_count }}
              </span>
              <!-- <span class="state_font_size">173</span> -->
              <span class="font_size_small">{{ $t("label.L0040") }}</span>
            </div>
          </div>
          <!-- <div style="height: 100%; width: 1.6%"></div>
          <div style="height: 100%; width: 15%" v-show="fire_YN">
            <div class="state_div_top">
              <img
                src="resources/img/icons/dashboard/effect/Fire.svg"
                style="width: 1.5vw; margin-right: 0.1vw"
              />
              <span class="state_title">&nbsp;{{ $t("label.L0024") }}</span>
            </div>
            <div class="state_div_bot_cl"> -->
          <!-- <img class="state_ball" src="resources/img/icons/dashboard/effect/pupple_ball.svg" v-show="reverse_unconfirmed_count == 0"/> -->
          <!-- <span class="state_font_size">
                {{ fire_count }}
              </span> -->
          <!-- <span class="state_font_size">173</span>-->
          <!-- <span class="font_size_small">{{ $t("label.L0040") }}</span>
            </div>
          </div> -->
          <div style="height: 100%; width: 1.6%"></div>
          <div style="height: 100%; width: 17%" v-show="delay_YN">
            <div class="state_div_top">
              <img
                src="resources/img/icons/dashboard/effect/Designation.svg"
                style="width: 1.5vw; margin-right: 0.1vw"
              />
              <span class="state_title">&nbsp;{{ $t("label.L0026") }}</span>
            </div>
            <div class="state_div_bot_cl">
              <!-- <img class="state_ball" src="resources/img/icons/dashboard/effect/blue_ball.svg" v-show="delay_unconfirmed_count == 0"/> -->
              <span class="state_font_size_ec">
                {{ delay_count }}
              </span>
              <!-- <span class="state_font_size">173</span> -->
              <span class="font_size_small">{{ $t("label.L0040") }}</span>
            </div>
          </div>
          <div style="height: 100%; width: 1.6%"></div>
          <div style="height: 100%; width: 17%" v-show="sudden_stop_YN">
            <div class="state_div_top">
              <img
                src="resources/img/icons/dashboard/effect/SuddenStop.svg"
                style="width: 1.5vw; margin-right: 0.1vw"
              />
              <span class="state_title">&nbsp;{{ $t("label.L0025") }}</span>
            </div>
            <div class="state_div_bot_cl">
              <span class="state_font_size_ec">
                {{ sudden_stop_count }}
              </span>
              <span class="font_size_small">{{ $t("label.L0040") }}</span>
            </div>
          </div>
          <div style="height: 100%; width: 1.6%"></div>
          <div style="height: 100%; width: 17%" v-show="stop_car_YN">
            <div class="state_div_top">
              <img
                src="resources/img/icons/dashboard/effect/StopCar.svg"
                style="width: 1.5vw; margin-right: 0.1vw"
              />
              <span class="state_title">&nbsp;{{ $t("label.L0027") }}</span>
            </div>
            <div class="state_div_bot_cl">
              <!-- <img class="state_ball" src="resources/img/icons/dashboard/effect/red_ball.svg" v-show="stop_car_unconfirmed_count == 0"/> -->
              <span class="state_font_size_ec">
                {{ stop_car_count }}
              </span>
              <!-- <span class="state_font_size">173</span> -->
              <span class="font_size_small">{{ $t("label.L0040") }}</span>
            </div>
          </div>
          <div style="height: 100%; width: 1.6%"></div>
          <div style="height: 100%; width: 17%" v-show="reverse_YN">
            <div class="state_div_top">
              <img
                src="resources/img/icons/dashboard/effect/Reverse.svg"
                style="width: 1.5vw; margin-right: 0.1vw"
              />
              <span class="state_title">&nbsp;{{ $t("label.L0023") }}</span>
            </div>
            <div class="state_div_bot_cl">
              <!-- <img class="state_ball" src="resources/img/icons/dashboard/effect/pupple_ball.svg" v-show="reverse_unconfirmed_count == 0"/> -->
              <span class="state_font_size_ec">
                {{ reverse_count }}
              </span>
              <!-- <span class="state_font_size">173</span>-->
              <span class="font_size_small">{{ $t("label.L0040") }}</span>
            </div>
          </div>
          <div style="height: 100%; width: 1.6%"></div>
          <div style="height: 100%; width: 17%" v-show="pedestrian_YN">
            <div class="state_div_top">
              <img
                src="resources/img/icons/dashboard/effect/Walker.svg"
                style="width: 1.5vw; margin-right: 0.1vw"
              />
              <span class="state_title">&nbsp;{{ $t("label.L0022") }}</span>
            </div>
            <div class="state_div_bot_cl">
              <!-- <img class="state_ball" src="resources/img/icons/dashboard/effect/green_ball.svg" v-show="pedestrian_unconfirmed_count == 0"/> -->
              <span class="state_font_size_ec">
                {{ pedestrian_count }}
              </span>
              <!-- <span class="state_font_size">173</span> -->
              <span class="font_size_small">{{ $t("label.L0040") }}</span>
            </div>
          </div>
          <!-- <div style="height: 100%; width: 1.6%"></div>
          <div style="height: 100%; width: 15%" v-show="sound_YN">
            <div class="state_div_top">
              <img
                src="resources/img/icons/dashboard/effect/WeirdSound.svg"
                style="width: 1.5vw; margin-right: 0.1vw"
              />
              <span class="state_title">&nbsp;{{ $t("label.L0030") }}</span>
            </div>
            <div class="state_div_bot_cl"> -->
          <!-- <img class="state_ball" src="resources/img/icons/dashboard/effect/pink_ball.svg" v-show="sound_unconfirmed_count == 0" /> -->
          <!-- <span class="state_font_size">
                {{ sound_count }}
                </span> -->
          <!-- <span class="state_font_size">173</span> -->
          <!-- <span class="font_size_small">{{ $t("label.L0040") }}</span>
            </div>
          </div> -->
          <!-- <div style="height: 100%; width: 1.6%"></div> -->
        </div>
        <div class="state_space"></div>
        <div
          class="state_div_big_r state_content"
          style="display: inline-flex; text-align: center"
        >
          <!-- <div style="height: 100%; width: 15%;"></div> -->
          <div v-show="view_radar_state" style="height: 100%; width: 50%">
            <div class="state_div_top_r">
              <img
                src="resources/img/icons/dashboard/effect/Camera_4.svg"
                style="width: 1.4vw"
              />
              <span class="state_title">&nbsp;{{ $t("label.L0029") }}</span>
            </div>
            <div class="state_div_bot_r">
              <div class="state_div_namal">
                <span class="state_nomal nomal-padding-custom">{{
                  $t("label.L0041")
                }}</span>
                <span class="state_font_size number-custom">
                  {{ radar_green }}
                </span>
                <span
                  class="font_size_small text-custom"
                  style="margin-left: 0.5rem"
                  >&nbsp;{{ $t("label.L0034") }}</span
                >
              </div>
              <div class="state_div_error">
                <span class="state_error nomal-padding-custom">{{
                  $t("label.L0042")
                }}</span>
                <span class="state_font_size number-custom">
                  {{ radar_red }}
                </span>
                <span
                  class="font_size_small text-custom"
                  style="margin-left: 0.5rem"
                  >&nbsp;{{ $t("label.L0034") }}</span
                >
              </div>
            </div>
          </div>
          <!-- <div
            v-show="view_sound_state"
            style="height: 100%; width: 35%; margin-left: 1vw"
          >
            <div class="state_div_top_r" style="margin-left: -8px">
              <img
                src="resources/img/icons/dashboard/effect/Microphone_2.svg"
                style="width: 1.4vw"
              />
              <span class="state_title">&nbsp;{{ $t("label.L0030") }}</span>
            </div>
            <div class="state_div_bot_r">
              <div class="state_div_namal">
                <span class="state_nomal nomal-padding-custom">{{
                  $t("label.L0041")
                }}</span>
                <span
                  class="state_font_size number-custom"
                  v-text="sound_green"
                >
                </span>
                <span class="font_size_small text-custom"
                  >&nbsp;{{ $t("label.L0034") }}</span
                >
              </div>
              <div class="state_div_error">
                <span class="state_error nomal-padding-custom">{{
                  $t("label.L0042")
                }}</span>
                <span class="state_font_size number-custom">
                  {{ sound_red }}
                </span>
                <span class="font_size_small text-custom"
                  >&nbsp;{{ $t("label.L0034") }}</span
                >
              </div>
            </div>
          </div> -->
          <div v-show="view_cctv_state" style="height: 100%; width: 50%">
            <div class="state_div_top_r" style="margin-left: -3px">
              <img
                src="resources/img/icons/dashboard/cctv/n_5_on.svg"
                style="width: 1.4vw; height: 20px"
              />
              <span class="state_title">&nbsp;{{ $t("label.L0031") }}</span>
            </div>
            <div class="state_div_bot_r">
              <div class="state_div_namal">
                <span class="state_nomal nomal-padding-custom">{{
                  $t("label.L0041")
                }}</span>
                <span class="state_font_size number-custom">
                  {{ cctv_green }}
                </span>
                <span
                  class="font_size_small text-custom"
                  style="margin-left: 0.5rem"
                  >&nbsp;{{ $t("label.L0034") }}</span
                >
              </div>
              <div class="state_div_error">
                <span class="state_error nomal-padding-custom">{{
                  $t("label.L0042")
                }}</span>
                <span class="state_font_size number-custom">
                  {{ cctv_red }}
                </span>
                <span
                  class="font_size_small text-custom"
                  style="margin-left: 0.5rem"
                  >&nbsp;{{ $t("label.L0034") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import { TunnelMap } from "@/components/d3/tunnelMap.js";
// import AnimatedNumber from "animated-number-vue";
import dayjs from "dayjs";
import EventBus from "@/event-bus";
import { BContainer, BRow } from "bootstrap-vue";
import { RoadMap } from "@/components/d3/roadMap.js";
import { forEach } from "postcss-rtl/lib/affected-props";

export default {
  name: "State",
  components: {
    BContainer,
    BRow,
  },
  data: function () {
    return {
      // 임시
      //pildong_sound: 10,
      //hannamdong_sound: 10,

      //pildong_sound_timer: null,
      //hannamdong_sound_timer: null,
      iconHoverTrigger: "",
      // select_pildong_index: 0,
      // select_hannamdong_index : 0,
      select_bundang_index: 0,
      select_seoul_index: 0,

      // pildong_animation_top: 8,
      // pildong_animation_clip: 'inset(0px 0px 6vh)',
      bundang_animation_top: 8,
      bundang_animation_clip: "inset(0px 0px 6vh)",

      // hannamdong_animation_top: 8,
      // hannamdong_animation_clip: 'inset(0px 0px 6vh)',
      seoul_animation_top: 8,
      seoul_animation_clip: "inset(0px 0px 6vh)",

      // select_hannamdong : {		code: '192.168.0.4',		name: '한남동#1',	 timer: null,	 volumn: 10, random: 15},
      // select_pildong : {		code: '192.168.0.1',		name: '필동#1',			timer: null,	volumn: 10, random: 15},
      select_seoul: {
        code: "192.168.0.4",
        name: "서울#1",
        timer: null,
        volumn: 10,
        random: 15,
      },
      select_bundang: {
        code: "192.168.0.1",
        name: "분당#1",
        timer: null,
        volumn: 10,
        random: 15,
      },

      setState: {
        // 1: $t("label.L0091"),
        // 2: $t("label.L0092"),
        // 3: $t("label.L0093"),
        // 4: $t("label.L0094"),
        1: "사고음",
        2: "경적음",
        3: "급정거음",
        4: "노이즈",
      },

      dates: {
        start: new Date(),
        end: new Date(),
      },

      demoSpeedInterval: null,
      bundang_sound_list: [],
      seoul_sound_list: [
        {
          code: "192.168.205.31",
          name: "서울(하행)#1",
          timer: null,
          position: "0.15",
          state: "정상",
          volumn: 10,
          random: 15,
          warning: false,
          index: 0,
        },
        {
          code: "192.168.205.32",
          name: "서울(하행)#2",
          timer: null,
          position: "0.30",
          state: "정상",
          volumn: 10,
          random: 15,
          warning: false,
          index: 1,
        },
        {
          code: "192.168.205.33",
          name: "서울(하행)#3",
          timer: null,
          position: "0.45",
          state: "정상",
          volumn: 10,
          random: 15,
          warning: false,
          index: 2,
        },
        {
          code: "192.168.205.34",
          name: "서울(하행)#4",
          timer: null,
          position: "0.73",
          state: "정상",
          volumn: 10,
          random: 15,
          warning: false,
          index: 3,
        },
        {
          code: "192.168.205.35",
          name: "서울(하행)#5",
          timer: null,
          position: "0.88",
          state: "정상",
          volumn: 10,
          random: 15,
          warning: false,
          index: 4,
        },
        {
          code: "192.168.205.36",
          name: "서울(하행)#6",
          timer: null,
          position: "1.03",
          state: "정상",
          volumn: 10,
          random: 15,
          warning: false,
          index: 5,
        },
      ],
      selected_tunnel_code: "",
      tunnel_to_linkcode: {
        // 터널별 link_code값 가진 변수
      },
      delay_YN: false,
      stop_car_YN: false,
      sudden_stop_YN: false,
      reverse_YN: false,
      fire_YN: false,
      pedestrian_YN: false,
      sound_YN: false,
      total_count: 0,
      delay_count: 0,
      stop_car_count: 0,
      reverse_count: 0,
      fire_count: 0,
      pedestrian_count: 0,
      sudden_stop_count: 0,
      sound_count: 0,
      cctv_count: 0,
      rader_count: 0,
      // unconfirmed count //
      total_unconfirmed_count: 0,
      delay_unconfirmed_count: 0,
      stop_car_unconfirmed_count: 0,
      reverse_unconfirmed_count: 0,
      pedestrian_unconfirmed_count: 0,
      sound_unconfirmed_count: 0,
      sudden_stop_unconfirmed_count: 0,
      fire_unconfirmed_count: 0,

      stagnation: 0,
      delay: 0,
      slow: 0,
      smooth: 0,

      view_radar_state: true,
      view_cctv_state: true,
      view_sound_state: true,
      radar_green: 0,
      radar_red: 0,
      cctv_green: 0,
      cctv_red: 0,
      sound_green: 0,
      sound_red: 0,
      vms_green: 0,
      vms_red: 0,
      lcs_green: 0,
      lcs_red: 0,
      initTimer: null,
      emergency_green: 0,
      emergency_red: 0,
      avr_speed_color_s: "#2CE999",
      number_duration: 500,
      current_code: null,
      status: {}, // 모든 장비 상태 가진 변수 -> 이 값을 이용해 장비 상태 표시됨
      tunnelAvrSpeedMap: {},
      currentAvrSpeed: {
        s: {},
        e: {},
      },
      statusList: [],
      directionUp: null,
      directionDown: null,
      tunnelData: [],
      roadMap: null,
      nowTunnelInfo: {
        s: {},
        e: {},
      },
      selectTunnelInfo: {},
      drawTunnelTabDataArr: [],
      eventTunnelTabMove: [],
      dbsStateContentTf: false,
      stateDivBig: false,
      temp1: 0,
      oldAvg1: 0,
      oldAvg2: 0,
    };
  },
  methods: {
    tunnelClickEvent: function (data, eventTunnelMoveTf) {
      // "class=eventGroup"인 g 요소를 선택합니다.
      var eventGroup = document.querySelector(".eventGroup");
      if (eventGroup != undefined) {
        // 해당 요소의 모든 자식 요소를 삭제합니다.
        while (eventGroup.firstChild) {
          eventGroup.removeChild(eventGroup.firstChild);
        }
      }
      // if(eventTunnelMoveTf == true){
      //   console.log("🚀 ~ eventTunnelMoveTf:", eventTunnelMoveTf)
      // }

      // 활동중인 공사이정에서 하행 클래스를 가진 요소 선택
      let downScheduleGroup = document.querySelectorAll(".scheduleGroup0");

      // 각 요소에 대해 반복
      downScheduleGroup.forEach((element) => {
        // 자식 요소가 없는 경우 부모 요소 삭제
        if (element.children.length > 0) {
          element.remove();
        }
      });

      // 활동중인 공사이정에서 상행 클래스를 가진 요소 선택
      let upScheduleGroup = document.querySelectorAll(".scheduleGroup1");

      // 각 요소에 대해 반복
      upScheduleGroup.forEach((element) => {
        // 자식 요소가 없는 경우 부모 요소 삭제
        if (element.children.length > 0) {
          element.remove();
        }
      });

      // tooltipBody 클래스를 가진 요소 선택
      let tooltipBody = document.querySelectorAll(".tooltipBody");
      if (tooltipBody.length > 0) {
        for (let i = 0; i < tooltipBody.length; i++) {
          tooltipBody[i].remove();
        }
      }

      //현재 터널 정보 호출
      EventBus.$emit("tunnelChkVal", true);

      //현재 터널 정보 호출
      EventBus.$emit("selectTunnelInfo", this.selectTunnelInfo[data.code]);
      let tunnel_index = 0;

      this.tunnelMap.selectTunnel(data);
      // if ("number" === typeof data) {
      // 	tunnel_index = data;
      // } else {
      // 	tunnel_index = data.index;
      // }

      // let tunnel_info = {
      // 	link_codes: this.tunnel_to_linkcode[this.tn_num_to_string[tunnel_index]],
      // 	tech: this.tn_num_to_string[tunnel_index] === 'DJ2' ? 'R' : 'C'
      // };

      // this.current_code = this.tn_num_to_string[tunnel_index];
      this.selected_tunnel_code = data.code;
      var selected_tunnel_port = data.port;

      // 현재 선택된 터널코드 호출
      EventBus.$emit(
        "selectTunnelCode",
        this.selected_tunnel_code,
        selected_tunnel_port
      );
      EventBus.$emit("selectTunnelEndposition", data.endposition);
      // EventBus.$emit('selectTunnelStartLinkCode', this.tunnel_to_linkcode[this.selected_tunnel_code][0]);

      // 방면별 속도
      // this.setCurrntAvrSpeed();
      this.getAvrSpeed();

      // 돌발 이력 카운트
      // this.getEventCount(tunnel_info.link_codes);
      this.getEventCount();

      // 장비상태 선택된 터널로 초기화
      this.initStatus(data);

      // this.$emit('tunnelInfo', tunnel_info); // 사용 X -> $on 받는곳 없음
      // EventBus.$emit("tunnelClick", data);

      //터널명
      EventBus.$emit("selectTunnelName", data.location);

      if (this.selected_tunnel_code == "DBS") {
        this.dbsStateContentTf = false;
      } else {
        this.dbsStateContentTf = true;
      }
    },
    // jyp 대시보드의 터널별 탭 생성, 기능을 구현한다.
    async drawTunnelTab() {
      try {
        // jyp 터널별 탭 생성에 필요한 정보 조회한다.
        const selectDrawTunnelTabInfoList =
          await this.api.selectDrawTunnelTabInfoList();

        // jyp data 상수에 터널별 탭 생성에 필요한 정보 조회한 데이터를 담는다.
        const { data } = selectDrawTunnelTabInfoList.data;

        // jyp DrawTunnelTabInfoList 상수에 data 안의 drawTunnelTabInfoList 변수 데이터를 담는다. drawTunnelTabInfoList 변수 데이터는 터널별 탭 생성에 필요한 데이터가 담아져있다.
        const DrawTunnelTabInfoList = data.drawTunnelTabInfoList;

        // 터널별 상,하행 link_code값 저장 변수
        let tunnelLinkCodeList = {};

        // jyp 터널별 탭 생성에 필요한 데이터만 담기 위한 상수 배열을 선언한다.
        this.drawTunnelTabDataArr = [];

        // jyp 터널별 탭 생성 담기
        for (var i = 0; i < DrawTunnelTabInfoList.length; i++) {
          const data = DrawTunnelTabInfoList[i];

          // jyp drawTunnelTabData 배열안에 터널별로 탭 생성에 필요한 데이터를 담는다.
          const drawTunnelTabData = {
            code: data.code, // "SBT"
            location: data.location, // "수락방음터널"
            flex: 1, // 터널별 탭 너비
            direction: data.direction, // "수락방음터널-하행"
            bound: data.bound, // 0
            port: data.port, // [6899,6900]
            endposition: data.endposition, // 100
          };

          this.drawTunnelTabDataArr.push(drawTunnelTabData);
        }

        // 터널별 링크코드값 저장
        this.tunnel_to_linkcode = tunnelLinkCodeList;

        // 터널 그리기 정보 세팅
        var tunnelMap = new TunnelMap();
        this.tunnelMap = tunnelMap;
        tunnelMap.tunnelData = this.drawTunnelTabDataArr;
        tunnelMap.event.click = this.tunnelClickEvent;
        tunnelMap.draw(); // 터널 그리기

        // jyp 초기 터널탭을 중간 터널탭으로 설정하기 위해 {{$t("label.L0021")}} 터널탭에서 /2 로 설정한다.
        // const tunnelInfoListCenterNum = DrawTunnelTabInfoList.length / 2;
        // this.tunnelClickEvent(drawTunnelTabDataArr[Math.floor(tunnelInfoListCenterNum)]);
        this.tunnelClickEvent(this.drawTunnelTabDataArr[0]);

        this.infoTunnelTab();
      } catch {}
    },
    async infoTunnelTab() {
      // jyp 터널별 탭 기능에 필요한 정보 조회한다.
      const selectTunnelTabInfoList = await this.api.selectTunnelTabInfoList();

      // jyp 터널별 탭 기능에 필요한 데이터만 담기 위한 상수 배열을 선언한다.
      const tunnelTabDataArr = [];

      // jyp data 상수에 터널별 탭 기능에 필요한 정보 조회한 데이터를 담는다.
      const { data } = selectTunnelTabInfoList.data;

      // jyp tunnelTabInfoList 상수에 data 안의 tunnelTabInfoList 변수 데이터를 담는다. tunnelTabInfoList 변수 데이터는 터널별 탭 기능에 필요한 데이터가 담아져있다.
      const tunnelTabInfoList = data.tunnelTabInfoList;

      // 터널별 상,하행 link_code값 저장 변수
      let tunnelLinkCodeList = {};

      //터널별 탭 정보 담기
      for (var i = 0; i < tunnelTabInfoList.length; i++) {
        const data = tunnelTabInfoList[i];

        // 1. 터널코드를 key로 가진 객체값 있는지 판별
        if (
          tunnelLinkCodeList[data.code] == null &&
          tunnelLinkCodeList[data.code] == undefined
        ) {
          // 2. 방면(상,하행) 들어갈 배열 초기화
          tunnelLinkCodeList[data.code] = [];
        }
        // 3. 터널별 상,하행 link_code 값 배열형태로 저장 ( splice 사용 이유 : bound 위치에 링크코드 위치시키기 위해 )
        // tunnelLinkCodeList[data.code].push(data.linkcode);
        tunnelLinkCodeList[data.code].splice(data.bound, 0, data.linkcode);

        // jyp tunnelTabData 배열안에 대시보드에서 기능에 필요한 터널별 데이터를 담는다.
        const tunnelTabData = {
          bound: data.bound, // 0
          lanecnt: data.lanecnt, // 3
          code: data.code, // "SBT"
          direction: data.direction, // "수락방음터널-하행"
          startposition: data.startp, // 0
          endposition: data.endp, // 150
        };

        tunnelTabDataArr.push(tunnelTabData);
      }

      // jyp code 기준으로 그룹화 하여 데이터 담기 위한 배열을 선언한다.
      const tunnelTabGroupCode = {};

      // jyp tunnelTabDataArr 리스트만큼 돌면서 code가 같은 배열끼리 그룹화 한다.
      tunnelTabDataArr.forEach((obj) => {
        const key = obj.code; //그룹 기준이 될 속성

        // jyp code가 다른 경우 tunnelTabGroupCode 배열안에 새로 배열을 만든다.
        if (!tunnelTabGroupCode[key]) {
          tunnelTabGroupCode[key] = [obj];
        } else {
          // jyp code가 같을 경우 같은 code 배열안에 추가한다.
          tunnelTabGroupCode[key].push(obj);
        }
      });

      // 터널별 링크코드값 저장
      this.tunnel_to_linkcode = tunnelLinkCodeList;

      // jyp 사용자가 터널탭을 클릭할 때마다 터널탭의 기능을 {{ $t("label.L0044") }}버스를 하기 위해 전역변수를 사용한다.
      this.selectTunnelInfo = tunnelTabGroupCode;

      EventBus.$emit(
        "selectTunnelInfo",
        this.selectTunnelInfo[this.selected_tunnel_code]
      );
    },
    initSoundTimer: function () {
      var me = this;
      EventBus.$on("setSoundTimer", function (sound_obj) {
        me.setTimer(
          sound_obj.code,
          sound_obj.min,
          sound_obj.max,
          sound_obj.cycle
        );
      });
      EventBus.$on("stopSoundTimer", function (code) {
        me.stopTimer(code);
      });
    },
    setTimer: function (code, min, max, cycle) {
      // 코드 , 최소, 최대, 주기
      var me = this;
      // for(var i = 0; i < me.pildong_sound_list.length; i++){
      // 	if(me.pildong_sound_list[i].code == code){
      // 		me.pildong_sound_list[i].warning = false; // 경고 초기화
      // 		me.pildong_sound_list[i].state = '정상'; //상태 초기화
      // 		me.pildong_sound_list[i].timer = setInterval(function(){
      // 			if(me.pildong_sound_list[i].volumn > me.pildong_sound_list[i].random){
      // 				me.pildong_sound_list[i].volumn = me.pildong_sound_list[i].volumn-1;
      // 			}
      // 			else if(me.pildong_sound_list[i].volumn < me.pildong_sound_list[i].random){
      // 				me.pildong_sound_list[i].volumn = me.pildong_sound_list[i].volumn+1;
      // 			}else{
      // 				me.pildong_sound_list[i].random = parseInt(Math.random()*(max-min)+min);
      // 			}
      // 		},cycle);
      // 		break;
      // 	}
      // }

      // for(var j =0; j < me.hannamdong_sound_list.length; j++){
      // 	if(me.hannamdong_sound_list[j].code == code){
      // 		me.hannamdong_sound_list[j].warning = false; // 경고 초기화
      // 		me.hannamdong_sound_list[j].state = '정상'; // 상태 초기화
      // 		me.hannamdong_sound_list[j].timer = setInterval(function(){
      // 				if(me.hannamdong_sound_list[j].volumn > me.hannamdong_sound_list[j].random){
      // 					me.hannamdong_sound_list[j].volumn = me.hannamdong_sound_list[j].volumn-1;
      // 				}
      // 				else if(me.hannamdong_sound_list[j].volumn < me.hannamdong_sound_list[j].random){
      // 					me.hannamdong_sound_list[j].volumn = me.hannamdong_sound_list[j].volumn+1;
      // 				}else{
      // 					me.hannamdong_sound_list[j].random = parseInt(Math.random()*(max-min)+min);
      // 				}
      // 		 },cycle);
      // 		 break;
      // 	}
      // }

      for (var i = 0; i < me.bundang_sound_list.length; i++) {
        if (me.bundang_sound_list[i].code == code) {
          me.bundang_sound_list[i].warning = false; // 경고 초기화
          me.bundang_sound_list[i].state = "정상"; //상태 초기화
          me.bundang_sound_list[i].timer = setInterval(function () {
            if (
              me.bundang_sound_list[i].volumn > me.bundang_sound_list[i].random
            ) {
              me.bundang_sound_list[i].volumn =
                me.bundang_sound_list[i].volumn - 1;
            } else if (
              me.bundang_sound_list[i].volumn < me.bundang_sound_list[i].random
            ) {
              me.bundang_sound_list[i].volumn =
                me.bundang_sound_list[i].volumn + 1;
            } else {
              me.bundang_sound_list[i].random = parseInt(
                Math.random() * (max - min) + min
              );
            }
          }, cycle);
          break;
        }
      }

      for (var j = 0; j < me.seoul_sound_list.length; j++) {
        if (me.seoul_sound_list[j].code == code) {
          me.seoul_sound_list[j].warning = false; // 경고 초기화
          me.seoul_sound_list[j].state = "정상"; // 상태 초기화
          me.seoul_sound_list[j].timer = setInterval(function () {
            if (me.seoul_sound_list[j].volumn > me.seoul_sound_list[j].random) {
              me.seoul_sound_list[j].volumn = me.seoul_sound_list[j].volumn - 1;
            } else if (
              me.seoul_sound_list[j].volumn < me.seoul_sound_list[j].random
            ) {
              me.seoul_sound_list[j].volumn = me.seoul_sound_list[j].volumn + 1;
            } else {
              me.seoul_sound_list[j].random = parseInt(
                Math.random() * (max - min) + min
              );
            }
          }, cycle);
          break;
        }
      }
    },

    stopTimer: function (code) {
      var me = this;

      // for(var i = 0; i < me.pildong_sound_list.length; i++){
      // 		if(me.pildong_sound_list[i].code == code){
      // 			clearInterval(me.pildong_sound_list[i].timer);
      // 			me.pildong_sound_list[i].volumn = 40;
      // 			me.pildong_sound_list[i].warning = true;
      // 		}
      // }
      // for(var j =0; j < me.hannamdong_sound_list.length; j++){
      // 	if(me.hannamdong_sound_list[j].code == code){
      // 		clearInterval(me.hannamdong_sound_list[j].timer);
      // 		me.hannamdong_sound_list[j].volumn = 40;
      // 		me.hannamdong_sound_list[j].warning = true;
      // 	}
      // }

      for (var i = 0; i < me.bundang_sound_list.length; i++) {
        if (me.bundang_sound_list[i].code == code) {
          clearInterval(me.bundang_sound_list[i].timer);
          me.bundang_sound_list[i].volumn = 40;
          me.bundang_sound_list[i].warning = true;
        }
      }
      for (var j = 0; j < me.seoul_sound_list.length; j++) {
        if (me.seoul_sound_list[j].code == code) {
          clearInterval(me.seoul_sound_list[j].timer);
          me.seoul_sound_list[j].volumn = 40;
          me.seoul_sound_list[j].warning = true;
        }
      }
      setTimeout(function () {
        var sound_obj = { code: code, min: 0, max: 20, cycle: 50 };
        me.setTimer(
          sound_obj.code,
          sound_obj.min,
          sound_obj.max,
          sound_obj.cycle
        );
      }, 15000);
    },

    async getEventCount() {
      let me = this;

      let radar_stop_car_count = 0;
      let radar_pedestrian_count = 0;
      let radar_reverse_count = 0;
      let radar_delay_count = 0;
      let radar_sudden_stop_count = 0;
      let radar_fire_count = 0;

      let cctv_stop_car_count = 0;
      let cctv_pedestrian_count = 0;
      let cctv_reverse_count = 0;
      let cctv_delay_count = 0;
      let cctv_sudden_stop_count = 0;
      let cctv_fire_count = 0;

      let stop_car_count = 0;
      let pedestrian_count = 0;
      let reverse_count = 0;
      let fire_count = 0;
      let delay_count = 0;
      let sound_count = 0;
      let cctv_count = 0;
      let radar_count = 0;
      let sudden_stop_count = 0;
      let accident_sound = 0;
      let horn_sound = 0;
      let sudden_sound = 0;
      let noise = 0;
      let total_count = 0;

      let delay_YN = false;
      let stop_car_YN = false;
      let sudden_stop_YN = false;
      let reverse_YN = false;
      let fire_YN = false;
      let pedestrian_YN = false;
      let sound_YN = false;

      let start_date = dayjs().startOf("day").format("YYYY-MM-DD HH:mm"); // 자정
      let end_date = dayjs().format("YYYY-MM-DD HH:mm");
      // console.log("this.selected_tunnel_code:::",this.selected_tunnel_code);
      // console.log("start_date:::",start_date);
      // console.log("end_date:::",end_date);
      try {
        const response = await this.api.getTotalCount({
          tunnelCode: this.selected_tunnel_code,
          startDate: start_date,
          endDate: end_date,
        });

        const { data } = response.data;
        let total_count_data = data.eventTotalCount;

        const response2 = await this.api.getSiteEventList({
          tunnelCode: this.selected_tunnel_code,
        });

        if (
          response2.data.data.siteEventList !== null &&
          response2.data.data.siteEventList !== undefined
        ) {
          if (response2.data.data.siteEventList.split(",") == null) return;
          var siteEventList = response2.data.data.siteEventList.split(",");

          siteEventList.forEach((d_event_type) => {
            if (d_event_type == "01") {
              stop_car_YN = true;
            } else if (d_event_type == "02") {
              pedestrian_YN = true;
            } else if (d_event_type == "03") {
              reverse_YN = true;
            } else if (d_event_type == "04") {
              sudden_stop_YN = true;
            } else if (d_event_type == "06") {
              fire_YN = true;
            } else if (d_event_type == "09") {
              delay_YN = true;
            } else if (
              d_event_type == "11" ||
              d_event_type == "12" ||
              d_event_type == "13" ||
              d_event_type == "14"
            ) {
              sound_YN = true;
            }
          });
        }

        this.stop_car_YN = stop_car_YN;
        this.pedestrian_YN = pedestrian_YN;
        this.reverse_YN = reverse_YN;
        this.fire_YN = fire_YN;
        this.sudden_stop_YN = sudden_stop_YN;
        this.delay_YN = delay_YN;
        this.sound_YN = sound_YN;

        if (total_count_data.length > 0) {
          total_count_data.forEach((value) => {
            if (1 == value.d_event_type) {
              //{{$t("label.L0027")}}
              stop_car_count += value.count;
              if (value.tech == "R") {
                radar_stop_car_count += value.count;
              } else if (value.tech == "C") {
                cctv_stop_car_count += value.count;
              }
            } else if (2 == value.d_event_type) {
              //{{$t("label.L0022")}}
              pedestrian_count += value.count;
              if (value.tech == "R") {
                radar_pedestrian_count += value.count;
              } else if (value.tech == "C") {
                cctv_pedestrian_count += value.count;
              }
            } else if (3 == value.d_event_type) {
              //{{$t("label.L0023")}}
              reverse_count += value.count;
              if (value.tech == "R") {
                radar_reverse_count += value.count;
              } else if (value.tech == "C") {
                cctv_reverse_count += value.count;
              }
            } else if (4 == value.d_event_type) {
              // {{$t("label.L0025")}}
              sudden_stop_count += value.count;
              if (value.tech == "R") {
                radar_sudden_stop_count += value.count;
              } else if (value.tech == "C") {
                cctv_sudden_stop_count += value.count;
              }
            } else if (6 == value.d_event_type) {
              // {{$t("label.L0024")}}
              fire_count += value.count;
              if (value.tech == "R") {
                radar_fire_count += value.count;
              } else if (value.tech == "C") {
                cctv_fire_count += value.count;
              }
            } else if (9 == value.d_event_type) {
              //{{$t("label.L0026")}}
              delay_count += value.count;
              if (value.tech == "R") {
                radar_delay_count += value.count;
              } else if (value.tech == "C") {
                cctv_delay_count += value.count;
              }
            } else if (11 == value.d_event_type) {
              //{{$t("label.L0091")}}<
              sound_count += value.count;
              accident_sound += value.count;
            } else if (12 == value.d_event_type) {
              //{{$t("label.L0092")}}<
              sound_count += value.count;
              horn_sound += value.count;
            } else if (13 == value.d_event_type) {
              //{{$t("label.L0093")}}<
              sound_count += value.count;
              sudden_sound += value.count;
            } else if (14 == value.d_event_type) {
              //{{$t("label.L0094")}}<
              sound_count += value.count;
              noise += value.count;
            }
            total_count = total_count + value.count;
          });
        }
        EventBus.$emit("EffectSoundCount", 0, 0, true);
        EventBus.$emit("EffectSoundCount", 11, accident_sound, false);
        EventBus.$emit("EffectSoundCount", 12, horn_sound, false);
        EventBus.$emit("EffectSoundCount", 13, sudden_sound, false);
        EventBus.$emit("EffectSoundCount", 14, noise, false);

        EventBus.$emit("EffectRadarCount", 0, 0, true);
        EventBus.$emit("EffectRadarCount", 1, radar_stop_car_count, false);
        EventBus.$emit("EffectRadarCount", 2, radar_pedestrian_count, false);
        EventBus.$emit("EffectRadarCount", 3, radar_reverse_count, false);
        EventBus.$emit("EffectRadarCount", 4, radar_sudden_stop_count, false);
        EventBus.$emit("EffectRadarCount", 6, radar_fire_count, false);
        EventBus.$emit("EffectRadarCount", 9, radar_delay_count, false);

        EventBus.$emit("EffectCctvCount", 0, 0, true);
        EventBus.$emit("EffectCctvCount", 1, cctv_stop_car_count, false);
        EventBus.$emit("EffectCctvCount", 2, cctv_pedestrian_count, false);
        EventBus.$emit("EffectCctvCount", 3, cctv_reverse_count, false);
        EventBus.$emit("EffectCctvCount", 4, cctv_sudden_stop_count, false);
        EventBus.$emit("EffectCctvCount", 6, cctv_fire_count, false);
        EventBus.$emit("EffectCctvCount", 9, cctv_delay_count, false);

        me.stop_car_count = stop_car_count;
        me.pedestrian_count = pedestrian_count;
        me.reverse_count = reverse_count;
        me.delay_count = delay_count;
        me.sound_count = sound_count;
        me.radar_count = radar_count;
        me.cctv_count = cctv_count;
        me.sudden_stop_count = sudden_stop_count;
        me.fire_count = fire_count;
        me.total_count = total_count;
      } catch (err) {
        console.error(err);
      }
    },

    async getAvrSpeed() {
      let me = this;
      // me.tunnelAvrSpeedMap = {}; // 터널의 방면별 속도 정보 초기화
      // me.currentAvrSpeed = { // UI 표출변수 초기화
      //   s: {},
      //   e: {},
      // }

      const response = await this.api.selectAvrSpeed({
        tunnelCode: me.selected_tunnel_code, // 터널 코드 ( 예시 : NS2 )
      });
      const { data } = response.data;
      let trafficInfoList = data.trafficInfoList;
      EventBus.$emit("selectTunnelInfoList", trafficInfoList); // state.vue에서 터널코드 값을 받아와 세팅 후 api 호출
      me.changeAvrSpeed(trafficInfoList, "db");
    },

    // setCurrntAvrSpeed: function () {
    //   var linkCode = this.tunnel_to_linkcode[this.selected_tunnel_code];
    //   var tunnelInfo = this.selectTunnelInfo[this.selected_tunnel_code];

    //   this.tunnelAvrSpeedMap[linkCode[0]].title = tunnelInfo[0].direction;
    //   this.tunnelAvrSpeedMap[linkCode[1]].title = tunnelInfo[1].direction;
    //   this.currentAvrSpeed.s = this.tunnelAvrSpeedMap[linkCode[0]];
    //   this.currentAvrSpeed.e = this.tunnelAvrSpeedMap[linkCode[1]];

    //   // console.log('setCurrntAvrSpeed :: ' , this.currentAvrSpeed);
    //   // console.log(' a :: ' ,  a);
    //   // console.log('selectedTunnelInfo :: ' , selectedTunnelInfo);
    // },

    changeAvrSpeed: function (data, quarter) {
      // let list = null ;

      // // 1. 현재 선택된 탭의 터널정보만 필터링 하는 로직
      // list = data.filter( obj => {
      //   let selectedObjTunnelCode = null ; // 터널별 link_code를 터널코드로 변환해 저장할 변수

      //   // 2. data에 터널값 있는지 체크 -> 빈값이거나 obj.link_code 비어있는 경우 체크할 필요가 없음.
      //   if(obj == null && obj.link_code == null && obj.link_code == ""){
      //     return ;
      //   }

      //   // 3. link_code 이용 터널코드 추출
      //   selectedObjTunnelCode = obj.link_code.substr(-3);

      //   // 4. 현태 선택된 탭의 데이터만 list로 저장
      //   if(selectedObjTunnelCode == this.selected_tunnel_code){
      //     return obj;
      //   }
      // })

      // list = data ;
      let list = [...data];

      switch (quarter) {
        // 리프레쉬 및 터널탭 이동시 호출
        case "db": {
          // 1. 이전 속도 비교를 위해 터널 속도 정보 저장값 초기화 ( 터널정보가 달라졌기 때문 )
          this.tunnelAvrSpeedMap = {};
          this.currentAvrSpeed = {
            s: {},
            e: {},
          };

          for (var i = 0; i < list.length; i++) {
            // 전송받은 터널의 방면 데이터
            var trafficInfo = list[i];
            // 변수에 담지 않으면 중간에 this.tunnelAvrSpeedMap의 값이 달라질 수 있음. ( 이전 평균 속도를 가짐 )
            var tunnelAvrSpeed = this.tunnelAvrSpeedMap[trafficInfo.link_code];

            // 2. 터널의 방면별 속도 초기 정보 세팅
            if (tunnelAvrSpeed == null) {
              tunnelAvrSpeed = {
                title: trafficInfo.direction,
                avrSpeed: trafficInfo.avr_speed,
                bound: trafficInfo.bound,
                compareSpeed: trafficInfo.compare_speed,
                arrowDirection: true,
                lastSpeed: trafficInfo.avr_speed,
                totalSpeed: 0,
                count: 0,
              };
            }
            // tunnelAvrSpeed.avrSpeed = trafficInfo.avr_speed;
            // tunnelAvrSpeed.lastSpeed = trafficInfo.avr_speed;

            // // 3. 이전 속도와 현재 속도 비교값 세팅
            // if (
            //   tunnelAvrSpeed.compareSpeed != null &&
            //   tunnelAvrSpeed.compareSpeed != 0
            //   ) {
            //   tunnelAvrSpeed.compareSpeed =
            //     trafficInfo.avr_speed - trafficInfo.compare_speed;
            // } else {
            //   tunnelAvrSpeed.compareSpeed = 0;
            // }

            // 4. 속도 비교후 화살표 세팅
            if (tunnelAvrSpeed.compareSpeed >= 0) {
              tunnelAvrSpeed.arrowDirection = true;
            } else {
              tunnelAvrSpeed.compareSpeed *= -1;
              tunnelAvrSpeed.arrowDirection = false;
            }

            // 5. 방면별 속도 값 세팅
            this.tunnelAvrSpeedMap[trafficInfo.link_code] = tunnelAvrSpeed;
          }

          // UI표출변수에 터널의 방면별 속도 정보 세팅
          for (var i = 0; i < list.length; i++) {
            if (list[i].bound == 1) {
              // 상행
              this.currentAvrSpeed.s =
                this.tunnelAvrSpeedMap[list[i].link_code];
            } else if (list[i].bound == 0) {
              // 하행
              this.currentAvrSpeed.e =
                this.tunnelAvrSpeedMap[list[i].link_code];
            }
          }

          break;
        }

        // esb의 ids_traffic_sche 의 스케쥴러에 의해 호출
        case "socket": {
          for (var i = 0; i < list.length; i++) {
            var linkcode = list[i].link_code;
            if (linkcode.includes(this.selected_tunnel_code)) {
              list.forEach((value) => {
                var linkcode2 = value.link_code;
                if (linkcode2.includes(this.selected_tunnel_code)) {
                  this.tunnelAvrSpeedMap[value.link_code].totalSpeed +=
                    +value.avr_speed;
                  this.tunnelAvrSpeedMap[value.link_code].count++;
                }
              });
              this.tunnelAvrSpeedMap[linkcode].avrSpeed = isNaN(
                this.tunnelAvrSpeedMap[linkcode].totalSpeed /
                  this.tunnelAvrSpeedMap[linkcode].count
              )
                ? 0
                : this.tunnelAvrSpeedMap[linkcode].totalSpeed /
                  this.tunnelAvrSpeedMap[linkcode].count;
              if (
                this.tunnelAvrSpeedMap[linkcode].avrSpeed -
                  this.tunnelAvrSpeedMap[linkcode].lastSpeed >
                0
              ) {
                this.tunnelAvrSpeedMap[linkcode].compareSpeed =
                  this.tunnelAvrSpeedMap[linkcode].avrSpeed -
                  this.tunnelAvrSpeedMap[linkcode].lastSpeed;
                this.tunnelAvrSpeedMap[linkcode].arrowDirection = true;
              } else {
                this.tunnelAvrSpeedMap[linkcode].arrowDirection = false;
                this.tunnelAvrSpeedMap[linkcode].compareSpeed = Math.abs(
                  this.tunnelAvrSpeedMap[linkcode].avrSpeed -
                    this.tunnelAvrSpeedMap[linkcode].lastSpeed
                );
              }
              this.tunnelAvrSpeedMap[linkcode].totalSpeed = 0;
              this.tunnelAvrSpeedMap[linkcode].count = 0;
              this.tunnelAvrSpeedMap[linkcode].lastSpeed =
                this.tunnelAvrSpeedMap[linkcode].avrSpeed;
            }
          }

          /*for (var i = 0; i < list.length; i++) {
            // socket 전송받은 터널의 방면 데이터 
            var trafficInfo = list[i]; 
            // 변수에 담지 않으면 중간에 바인딩때문에 this.tunnelAvrSpeedMap의 값이 달라질 수 있음. ( 이전 평균 속도를 가짐 )
            var tunnelAvrSpeed = this.tunnelAvrSpeedMap[trafficInfo.link_code];

            // 1. socket 넘어온 값으로 평균속도 변경 
            tunnelAvrSpeed.avrSpeed = trafficInfo.avr_speed;

            // 2. 넘어온 평균속도와 이전 표시되던 평균속도 비교 
            if ( tunnelAvrSpeed.avrSpeed - tunnelAvrSpeed.lastSpeed > 0 ) {
              // 이전 속도보다 증가했을 경우 (양수)
              tunnelAvrSpeed.arrowDirection = true; // 증가 화살표 (초록색)
              // 넘어온 평균속도와 이전 평균속도 차이값 계산 
              tunnelAvrSpeed.compareSpeed = (tunnelAvrSpeed.avrSpeed - tunnelAvrSpeed.lastSpeed);
            } else {
              // 이전 속도보다 감소했을 경우 (음수)
              tunnelAvrSpeed.arrowDirection = false; // 감소 화살표 (빨간색)
              // 넘어온 평균속도와 이전 평균속도 차이값 계산 
              tunnelAvrSpeed.compareSpeed = Math.abs(tunnelAvrSpeed.avrSpeed - tunnelAvrSpeed.lastSpeed);
            }

             // 3. {{ $t("button.B0009") }} socket으로 들어온 평균 속도와 비교하기 위해 저장 
            tunnelAvrSpeed.lastSpeed = tunnelAvrSpeed.avrSpeed;

            // 4. 넘어온 값으로 계산된 값을 세팅
            this.tunnelAvrSpeedMap[trafficInfo.link_code] = tunnelAvrSpeed;
             console.log("i 두번째 ::: ",i);
          } */
          break;
        }

        // case "socket": {
        //   console.log(' +++++++++++ 속도 변경 ::: socket 호출 +++++++++++')
        //   console.log('changeAvrSpeed .... socket ::: list : ', list);
        //   for (var i = 0; i < list.length; i++) {
        //     const linkcode = list[i].link_code;

        //     // 선택 탭 터널기준
        //     list.forEach((obj) => {
        //       this.tunnelAvrSpeedMap[obj.link_code].totalSpeed += obj.avr_speed;
        //       this.tunnelAvrSpeedMap[obj.link_code].count++;
        //     });

        //     //
        //     this.tunnelAvrSpeedMap[linkcode].avrSpeed =
        //       isNaN( this.tunnelAvrSpeedMap[linkcode].totalSpeed / this.tunnelAvrSpeedMap[linkcode].count )
        //         ? 0 : this.tunnelAvrSpeedMap[linkcode].totalSpeed / this.tunnelAvrSpeedMap[linkcode].count;

        //     // 속도 비교후 화살표 세팅
        //     if (
        //       this.tunnelAvrSpeedMap[linkcode].avrSpeed -
        //         this.tunnelAvrSpeedMap[linkcode].lastSpeed >
        //       0
        //     ) {
        //       // 평균속도보다 속도가 증가했을 경우 (양수)
        //       this.tunnelAvrSpeedMap[linkcode].arrowDirection = true; // 증가 화살표 (초록색)
        //       // 서브 표시 속도
        //       this.tunnelAvrSpeedMap[linkcode].compareSpeed =
        //         this.tunnelAvrSpeedMap[linkcode].avrSpeed -
        //         this.tunnelAvrSpeedMap[linkcode].lastSpeed;
        //     } else {
        //       // 평균속도보다 속도가 감소했을 경우 (음수)
        //       this.tunnelAvrSpeedMap[linkcode].arrowDirection = false; // 감소 화살표 (빨간색)
        //       // 서브 표시 속도
        //       this.tunnelAvrSpeedMap[linkcode].compareSpeed = Math.abs(
        //         this.tunnelAvrSpeedMap[linkcode].avrSpeed -
        //           this.tunnelAvrSpeedMap[linkcode].lastSpeed
        //       );
        //     }
        //     this.tunnelAvrSpeedMap[linkcode].totalSpeed = 0;
        //     this.tunnelAvrSpeedMap[linkcode].count = 0;
        //     this.tunnelAvrSpeedMap[linkcode].lastSpeed =
        //       this.tunnelAvrSpeedMap[linkcode].avrSpeed;
        //   }
        //   break;
        // }
      }

      // let viewTest = this.currentAvrSpeed;
      // console.log('속도변화 끝1 ::: viewTest : ',viewTest)
      // // UI표출변수에 터널의 방면별 속도 정보 세팅
      // for(var i = 0; i < list.length; i++){
      //   if(list[i].bound == 1 ){ // 상행
      //     this.currentAvrSpeed.s = this.tunnelAvrSpeedMap[list[i].link_code];
      //   } else if (list[i].bound == 0){ // 하행
      //     this.currentAvrSpeed.e = this.tunnelAvrSpeedMap[list[i].link_code];
      //   }
      // }
      // viewTest = this.currentAvrSpeed;
      // console.log('속도변화 끝2 ::: viewTest : ',viewTest)
    },
    getColor: function (speed) {
      if (speed >= this.smooth) return "#2CE999";
      else if (speed >= this.slow) return "#FFB431";
      else if (speed >= this.delay) return "#FF8E43";
      else return "#E64359";
      // if (speed >= 40) return "green";
      // else if (speed >= 20) return "yellow";
      // else if (speed >= 3) return "orange";
      // else return "red";
    },
    formatToSpeed: function (value) {
      var num = Number(value).toFixed(1);
      return `${Number(num).toLocaleString()}`;
    },
    tunnelUpdate: function () {
      this.tunnelMap.update();
    },
    addEventCount: function (data) {
      if (data.CameraID.includes(this.selected_tunnel_code)) {
        var type = this.getTypeFunction(data.CameraID);
        var eventType = parseInt(data.DEventType);
        if (type == "C") {
          if (eventType == 1) {
            this.stop_car_count = parseInt(this.stop_car_count) + 1;
            EventBus.$emit("EffectCctvCount", 1, 1, false);
          } else if (eventType == 2) {
            this.pedestrian_count = parseInt(this.pedestrian_count) + 1;
            EventBus.$emit("EffectCctvCount", 2, 1, false);
          } else if (eventType == 3) {
            this.reverse_count = parseInt(this.reverse_count) + 1;
            EventBus.$emit("EffectCctvCount", 3, 1, false);
          } else if (eventType == 4) {
            this.sudden_stop_count = parseInt(this.sudden_stop_count) + 1;
            EventBus.$emit("EffectCctvCount", 4, 1, false);
          } else if (eventType == 6) {
            this.fire_count = parseInt(this.fire_count) + 1;
            EventBus.$emit("EffectCctvCount", 6, 1, false);
          } else if (eventType == 9) {
            this.delay_count = parseInt(this.delay_count) + 1;
            EventBus.$emit("EffectCctvCount", 9, 1, false);
          }
          this.total_count = this.total_count + 1;
          return;
        }

        if (eventType == 1 || eventType == "01") {
          this.stop_car_count = parseInt(this.stop_car_count) + 1;
          EventBus.$emit("EffectRadarCount", 1, 1, false);
        } else if (eventType == 2 || eventType == "02") {
          this.pedestrian_count = parseInt(this.pedestrian_count) + 1;
          EventBus.$emit("EffectRadarCount", 2, 1, false);
        } else if (eventType == 3 || eventType == "03") {
          this.reverse_count = parseInt(this.reverse_count) + 1;
          EventBus.$emit("EffectRadarCount", 3, 1, false);
        } else if (eventType == 4 || eventType == "04") {
          this.sudden_stop_count = parseInt(this.sudden_stop_count) + 1;
          EventBus.$emit("EffectRadarCount", 4, 1, false);
        } else if (eventType == 6 || eventType == "06") {
          this.fire_count = parseInt(this.fire_count) + 1;
          EventBus.$emit("EffectRadarCount", 6, 1, false);
        } else if (eventType == 9 || eventType == "09") {
          this.delay_count = parseInt(this.delay_count) + 1;
          EventBus.$emit("EffectRadarCount", 9, 1, false);
        } else if (eventType == 11 || eventType == "11") {
          this.sound_count = parseInt(this.sound_count) + 1;
          EventBus.$emit("EffectSoundCount", 11, 1, false);
        } else if (eventType == 12 || eventType == "12") {
          this.sound_count = parseInt(this.sound_count) + 1;
          EventBus.$emit("EffectSoundCount", 12, 1, false);
        } else if (eventType == 13 || eventType == "13") {
          this.sound_count = parseInt(this.sound_count) + 1;
          EventBus.$emit("EffectSoundCount", 13, 1, false);
        } else if (eventType == 14 || eventType == "14") {
          this.sound_count = parseInt(this.sound_count) + 1;
          EventBus.$emit("EffectSoundCount", 14, 1, false);
        }
        this.total_count = this.total_count + 1;
      }
    },

    getTypeFunction(data) {
      var returnVal = "error";
      this.statusList.forEach((each) => {
        if (each.id == data) {
          returnVal = each.tech;
        }
      });
      return returnVal;
    },
    changeStatus: function (type, name, state) {
      if (this.status[type] == null) {
        this.status[type] = {};
      }

      var result = "F";

      if (state == 0 || state == "0") result = "S";
      else result = "F";

      this.status[type][name] = { status: result };
    },
    changeRadarStatus: function (name, state) {
      if (this.status["radar"] == null) {
        return;
      }

      if (this.status["radar"].hasOwnProperty(name)) {
        var result = "F";

        if (state == 0 || state == "0") result = "S";
        else result = "F";

        this.status["radar"][name] = { status: result };
      }
    },
    getStatus: function (type) {
      var result = { S: 0, F: 0 };

      for (var key in this.status) {
        if (key == type) {
          var statusList = this.status[key];
          for (var key2 in statusList) {
            var tStatus = statusList[key2];
            result[tStatus.status] = result[tStatus.status] + 1;
          }
        }
      }

      return result;
    },
    async initStatus(selectedTunnelInfo) {
      var me = this;

      try {
        // 장비 상태 리스트
        const response = await this.api.getDashboardStatusList({
          tunnelCode: selectedTunnelInfo.code, // 터널 코드 ( 예시 : NS2 )
        });

        const { data } = response.data;
        var statusList = data.statusList;
        me.statusList = data.statusList;
        this.status = {}; // 모든 장비상태값 가진 변수 초기화

        // 장비 표시 여부 결정
        const response2 = await this.api.getSiteDetectYnList({
          tunnelCode: this.selected_tunnel_code,
        });
        const siteDetectYnList = response2.data.data.siteDetectYnList;

        for (var i = 0; i < statusList.length; i++) {
          var equip = statusList[i];

          // if (equip.tech == "R" ) {
          //   // me.changeStatus("radar", equip.id.trim(), equip.state);
          //   me.changeStatus("radar", equip.id.trim(), 0);
          // } else if (equip.tech == "S") {
          //   // me.changeStatus("sound", equip.id.trim(), equip.state);
          //   me.changeStatus("sound", equip.id.trim(), 0);
          // } else if (equip.tech == "C") {
          //   // me.changeStatus("camera", equip.id.trim(), equip.state);
          //   me.changeStatus("camera", equip.id.trim(), 0);
          // }

          // 장비ID 기준으로 장비 상태값 업데이트
          if (equip.id.indexOf("RDR") > -1) {
            me.changeStatus("radar", equip.id.trim(), 0);
          } else if (equip.id.indexOf("SND") > -1) {
            me.changeStatus("sound", equip.id.trim(), 0);
          } else if (equip.id.indexOf("CAM") > -1) {
            me.changeStatus("camera", equip.id.trim(), 0);
          }
        }

        var radarState = me.getStatus("radar");
        var cameraState = me.getStatus("camera");
        var soundState = me.getStatus("sound");

        me.radar_green = radarState.S;
        me.radar_red = radarState.F;

        // // 장비 상태 표시 여부 결정.
        // if (radarState.S + radarState.F == 0) {
        //   me.view_radar_state = false; // 안보이도록 설정
        // }else{
        //   // 탭 이동시 초기화 필요
        //   me.view_radar_state = true; // 보이도록 설정
        // }

        me.cctv_green = cameraState.S;
        me.cctv_red = cameraState.F;

        // if (cameraState.S + cameraState.F == 0) {
        //   me.view_cctv_state = false;
        // }else{
        //   me.view_cctv_state = true; // 보이도록 설정
        // }

        me.sound_green = soundState.S;
        me.sound_red = soundState.F;

        // if (soundState.S + soundState.F == 0) {
        //   me.view_sound_state = false;
        // }else{
        //   me.view_sound_state = true; // 보이도록 설정
        // }

        // 장비 표시 여부 결정
        me.view_radar_state = siteDetectYnList["radar"];
        me.view_cctv_state = siteDetectYnList["camera"];
        me.view_sound_state = siteDetectYnList["sound"];
      } catch (err) {
        console.error(err);
      }
    },
    moveMenu: function (path) {
      if (this.$router.currentRoute.path != path) {
        this.$router.push({ path: path });
        EventBus.$emit("moveMenu", path);
      }
    },
    resetEventCount: function () {
      this.stop_car_count = 0;
      this.pedestrian_count = 0;
      this.reverse_count = 0;
      this.delay_count = 0;
      this.sudden_stop_count = 0;
      this.sound_count = 0;
      this.total_count = 0;
    },
    demoStateData: async function (tunnelCode) {
      var me = this;

      try {
        // const response = await this.api.getDashboardStatusList();
        const response = await this.api.getDashboardStatusList({
          tunnelCode: tunnelCode, // 터널 코드 ( 예시 : NS2 )
        });
        // this.axios.post('dashboard/status/list')
        // .then(function (response) {
        const { data } = response.data;
        var statusList = data.statusList;
        me.statusList = data.statusList;
        for (var i = 0; i < statusList.length; i++) {
          var equip = statusList[i];

          if (equip.tech == "R") {
            me.changeStatus("radar", equip.id.trim(), 0);
          } else if (equip.tech == "S") {
            me.changeStatus("sound", equip.id.trim(), 0);
          } else if (equip.tech == "C") {
            me.changeStatus("camera", equip.id.trim(), 0);
          }
        }

        var radarState = me.getStatus("radar");
        var cameraState = me.getStatus("camera");
        var soundState = me.getStatus("sound");

        me.radar_green = radarState.S + radarState.F;
        me.radar_red = 0;

        if (radarState.S + radarState.F == 0) {
          me.view_radar_state = false;
        }

        me.cctv_green = cameraState.S + cameraState.F;
        me.cctv_red = 0;

        if (cameraState.S + cameraState.F == 0) {
          me.view_cctv_state = false;
        }

        me.sound_green = soundState.S + soundState.F;
        me.sound_red = 0;

        if (soundState.S + soundState.F == 0) {
          me.view_sound_state = false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    demoSpeedData: function () {
      // 기존 인터벌 초기화
      let interval = this.demoSpeedInterval;

      if (interval != null) {
        clearInterval(interval);
        interval = null;
      }
      var me = this;

      this.demoSpeedInterval = setInterval(function () {
        // const min = 10;
        // const max = 90;
        // var avg1 =
        //   Math.floor(Math.random() * (max * 10 - min * 10) + min * 10) / 10;
        // var avg2 =
        //   Math.floor(Math.random() * (max * 10 - min * 10) + min * 10) / 10;
        let rNumberAvg1 = Math.floor(Math.random() * (70 - 58)) + 58;
        let rNumberAvg2 = Math.floor(Math.random() * (70 - 58)) + 58;
        var avg1 = rNumberAvg1.toFixed(0);
        var avg2 = rNumberAvg2.toFixed(0);
        //var compare1 = Math.floor(Math.random() * 5);
        //var compare2 = Math.floor(Math.random() * 5);

        var compare1 = Math.abs(avg1 - me.currentAvrSpeed.e.avrSpeed);
        var compare2 = Math.abs(avg2 - me.currentAvrSpeed.s.avrSpeed);

        if (me.currentAvrSpeed.e.avrSpeed >= avg1) {
          me.currentAvrSpeed.e.arrowDirection = false;
        } else if (me.currentAvrSpeed.e.avrSpeed < avg1) {
          me.currentAvrSpeed.e.arrowDirection = true;
        }

        if (me.currentAvrSpeed.s.avrSpeed >= avg2) {
          me.currentAvrSpeed.s.arrowDirection = false;
        } else if (me.currentAvrSpeed.s.avrSpeed < avg2) {
          me.currentAvrSpeed.s.arrowDirection = true;
        }

        me.currentAvrSpeed.e.avrSpeed =
          avg1 < 10 ? 22 : avg1 > 130 ? 121 : avg1;
        me.currentAvrSpeed.s.avrSpeed =
          avg2 < 10 ? 24 : avg2 > 130 ? 132 : avg2;

        var roundedCompare1 = compare1.toFixed(0);
        var roundedCompare2 = compare2.toFixed(0);

        var roundedCompare1Float = parseFloat(roundedCompare1);
        var roundedCompare2Float = parseFloat(roundedCompare2);

        me.currentAvrSpeed.e.compareSpeed = roundedCompare1Float;
        me.currentAvrSpeed.s.compareSpeed = roundedCompare2Float;
      }, 60000);
    },

    resetTimer: function (func) {
      var me = this;
      var date = new Date();
      var resetTime = new Date(date);

      resetTime.setDate(resetTime.getDate());
      resetTime.setHours(resetTime.getHours() + 1);
      resetTime.setMinutes(0);
      resetTime.setSeconds(0);
      // 테스트 //
      //resetTime.setDate(resetTime.getDate());
      //resetTime.setHours(resetTime.getHours());
      //resetTime.setMinutes(resetTime.getMinutes()+1);
      //resetTime.setSeconds(0);

      var waitTime = resetTime.getTime() - date.getTime();

      this.initTimer = setTimeout(function () {
        if (func != null && func instanceof Function) {
          try {
            func();
          } catch (e) {}
        }

        me.resetTimer(func);
      }, waitTime);
    },
    // demoData: function () {
    //   var me = this;

    //   var interval = setInterval(function () {
    //     var avg1 = Math.floor(Math.random() * 100 - 50) / 10;
    //     var avg2 = Math.floor(Math.random() * 100 - 50) / 10;

    //     var avr1 = (parseFloat(me.avr_speed_e) + avg1).toFixed(1);
    //     var avr2 = (parseFloat(me.avr_speed_s) + avg2).toFixed(1);

    //     me.avr_speed_e = avr1;
    //     me.avr_speed_s = avr2;

    //     if (avg1 >= 0) {
    //       me.speed_arrow_e = true;
    //       me.compare_speed_e = avg1.toFixed(1);
    //     } else {
    //       me.speed_arrow_e = false;
    //       me.compare_speed_e = (avg1 * -1).toFixed(1);
    //     }

    //     if (avg2 >= 0) {
    //       me.speed_arrow_s = true;
    //       me.compare_speed_s = avg2.toFixed(1);
    //     } else {
    //       me.speed_arrow_s = false;
    //       me.compare_speed_s = (avg2 * -1).toFixed(1);
    //     }
    //   }, 30000);
    // },

    toggleDisplay() {
      this.isHidden = !this.isHidden;
    },
  },

  created() {
    EventBus.$on("speedColorSetting", (stagnation, delay, slow, smooth) => {
      this.stagnation = Number(stagnation);
      this.delay = Number(delay);
      this.slow = Number(slow);
      this.smooth = Number(smooth);
    });

    EventBus.$on("changeMenu", this.changeMenu);

    var me = this;

    // this.socket.addListener(this, "traffic", function (data) {
    //   me.changeAvrSpeed(data.AvrSpeed, "socket");
    // });
    // this.socket.addListener(this, "sector_avrspd", function (data) {
    //   // me.changeAvrSpeed(data.AvrSpeed,'socket');
    // });

    EventBus.$on("occurEvent", (data) => {
      me.addEventCount(data);
    });

    // for(var i =0; i < me.pildong_sound_list.length; i++){
    // 	me.setTimer(me.pildong_sound_list[i].code,0,20,50);
    // }

    // for(var j =0; j < me.hannamdong_sound_list.length; j++){
    // 	me.setTimer(me.hannamdong_sound_list[j].code,0,20,50);
    // }

    // me.select_pildong = me.pildong_sound_list[0];
    // me.select_hannamdong = me.hannamdong_sound_list[0];

    for (var i = 0; i < me.bundang_sound_list.length; i++) {
      me.setTimer(me.bundang_sound_list[i].code, 0, 20, 50);
    }

    for (var j = 0; j < me.seoul_sound_list.length; j++) {
      me.setTimer(me.seoul_sound_list[j].code, 0, 20, 50);
    }

    me.select_bundang = me.bundang_sound_list[0];
    me.select_seoul = me.seoul_sound_list[0];

    me.initSoundTimer();

    // 사운드
    // this.socket.addListener(this, "wrongSoundInfo", function (data) {
    //   var dEventType = parseInt(data.wrongSound) + 3;
    //   var sound = { DEventType: dEventType };
    //   var device = null;
    //   // for(let i=0; i < me.pildong_sound_list.length; i++){
    //   // 	if(data.deviceId == me.pildong_sound_list[i].code){
    //   // 		me.pildong_sound_list[i].state = me.setState[data.wrongSound];
    //   // 		device = me.pildong_sound_list[i];
    //   // 	}
    //   // }
    //   // if(device != null){
    //   // 	me.selectSlidePildong(device.index);
    //   // }

    //   for (let i = 0; i < me.bundang_sound_list.length; i++) {
    //     if (data.deviceId == me.bundang_sound_list[i].code) {
    //       me.bundang_sound_list[i].state = me.setState[data.wrongSound];
    //       device = me.bundang_sound_list[i];
    //     }
    //   }
    //   if (device != null) {
    //     me.selectSlideBundang(device.index);
    //   } else {
    //     // for(let j=0; j < me.hannamdong_sound_list.length; j++){
    //     // 	if(data.deviceId == me.hannamdong_sound_list[j].code){
    //     // 		me.hannamdong_sound_list[j].state = me.setState[data.wrongSound];
    //     // 		device = me.hannamdong_sound_list[j];
    //     // 	}
    //     // }
    //     // if(device != null){
    //     // 	me.selectSlideHannamdong(device.index);
    //     // }

    //     for (let j = 0; j < me.seoul_sound_list.length; j++) {
    //       if (data.deviceId == me.seoul_sound_list[j].code) {
    //         me.seoul_sound_list[j].state = me.setState[data.wrongSound];
    //         device = me.seoul_sound_list[j];
    //       }
    //     }
    //     if (device != null) {
    //       me.selectSlideSeoul(device.index);
    //     }
    //   }
    //   me.addEventCount(sound);
    // });

    // this.socket.addListener(this, "status", function (data) {
    //   var statusList = data.StatusList;
    //   // console.log("statusList:::",statusList);
    //   var selectedTunnelCode = me.selected_tunnel_code;
    //   // console.log("selectedTunnelCode:::",selectedTunnelCode);
    //   var tunnelStatusList = statusList.filter(
    //     (obj) => obj.id.substr(0, 3) === selectedTunnelCode
    //   );
    //   // console.log("tunnelStatusList:::",tunnelStatusList);
    //   if (tunnelStatusList.length == 0) return;
    //   // if (statusList == undefined) return;

    //   for (var i = 0; i < tunnelStatusList.length; i++) {
    //     var equip = tunnelStatusList[i];
    //     // for (var i = 0; i < statusList.length; i++) {
    //     //   var equip = statusList[i];
    //     // if (equip.type == "R") {
    //     //   // me.changeStatus("radar", equip.id.trim(), equip.state);
    //     //   me.changeStatus("radar", equip.id.trim(), 0);
    //     // } else if (equip.type == "S") {
    //     //   // me.changeStatus("sound", equip.id.trim(), equip.state);
    //     //   me.changeStatus("sound", equip.id.trim(), 0);
    //     // } else if (equip.type == "C") {
    //     //   // me.changeStatus("camera", equip.id.trim(), equip.state);
    //     //   me.changeStatus("camera", equip.id.trim(), 0);
    //     // }

    //     // 장비ID 기준으로 장비 상태값 업데이트
    //     if (equip.id.indexOf("RDR") > -1) {
    //       me.changeStatus("radar", equip.id.trim(), equip.state);
    //     } else if (equip.id.indexOf("SND") > -1) {
    //       me.changeStatus("sound", equip.id.trim(), equip.state);
    //     } else if (equip.id.indexOf("CAM") > -1) {
    //       me.changeStatus("camera", equip.id.trim(), equip.state);
    //     }

    //     me.changeRadarStatus(equip.id, equip.state);
    //   }

    //   var radarState = me.getStatus("radar");
    //   var cameraState = me.getStatus("camera");
    //   var soundState = me.getStatus("sound");

    //   me.radar_green = radarState.S;
    //   me.radar_red = radarState.F;

    //   me.cctv_green = cameraState.S;
    //   me.cctv_red = cameraState.F;

    //   me.sound_green = soundState.S;
    //   me.sound_red = soundState.F;
    // });

    this.resetTimer(function () {
      //console.log("reset");
      me.resetEventCount();
      // me.getEventCount(me.tunnel_to_linkcode["DJ2"]);

      // EventBus.$emit("eventTableInit"); // 테이블 초기화
    });

    // EventBus.$on("storage", (res) => {
    //   console.log("Res : ",res)
    //   if (res["gs"] != undefined) {
    //     this.directionUp = res["gs"][0]["direction"][0]["up"];
    //     this.directionDown = res["gs"][0]["direction"][0]["down"];
    //   }
    // });

    var gsGlobalTarget = this.$store.getters["settingConfig/getTarget"]("gs");
    this.directionUp = gsGlobalTarget.direction.up;
    this.directionDown = gsGlobalTarget.direction.down;

    // demo 데이터 실행시 평균속도 랜덤 변경
    EventBus.$on("demoSpeedData", (res) => {
      me.demoSpeedData();
    });
    // demo 데이터 실행시 장비상태 정상
    EventBus.$on("demoStateData", (tunnelCode) => {
      me.demoStateData(tunnelCode);
    });

    // Alarm.vue에서 {{$t("label.L0125")}} {{ $t("label.L0044") }} 발생 시 발생 터널명 정보를 받는다.
    EventBus.$on("eventTunnelMove", (data, eventTunnelMoveTf) => {
      // console.log("🚀 ~ EventBus.$on ~ eventTunnelMoveTf:", eventTunnelMoveTf)
      let eventTunnelCode = this.drawTunnelTabDataArr.filter(
        (obj) => obj.code === data
      );
      this.tunnelClickEvent(eventTunnelCode[0], eventTunnelMoveTf);
    });

    // me.socket.addListener(this, "eventTable", function (data) {
    //   me.getEventCount();
    // });

    EventBus.$on("eventTable", (data) => {
      me.getEventCount(data);
    });
  },
  async mounted() {
    var me = this;
    let today = dayjs();
    let yesterday = dayjs().subtract(1, "day");

    me.dates.start = yesterday.format();
    me.dates.end = today.format();

    // this.setCurrntAvrSpeed();

    // me.getEventCount(me.tunnel_to_linkcode["DJ2"]);
    me.getEventCount();

    // 240105 jyp tb_ids_link_code 테이블 {{$t("label.L0021")}} 조회
    me.drawTunnelTab();

    // 240116 이예빈: eventTable로 선택된 터널코드 전달
    // 중복코드가 발생해 추후에 아래 방식 중 하나로 로직 변경될 수 있음
    // 1. watch를 이용해 selected_tunnel_code가 변경될 때마다 emit
    // 2. 그 외
    // 240131 jyp 중복코드로 판단하여 주석처리
    // EventBus.$emit('selectTunnelCode', this.selected_tunnel_code);
    // EventBus.$emit('selectTunnelStartLinkCode', this.tunnel_to_linkcode[this.selected_tunnel_code][0]);

    // var tunnelMap = new TunnelMap();
    // this.tunnelMap = tunnelMap;
    // tunnelMap.tunnelData = this.$data.tunnelData;
    // tunnelMap.event.click = this.tunnelClickEvent;
    // tunnelMap.draw();

    // this.tunnelClickEvent(this.tunnelData[1]);

    //EventBus.$on("tunnelUpdate", this.tunnelUpdate);

    /* EventBus.$on('AlarmToStateLinkCode', function(i_link_code) {
			if (me.selected_tunnel_code !== i_link_code) {
				me.selected_tunnel_code = i_link_code
				me.tunnelClickEvent(me.tunnelData[me.tn_string_to_num[i_link_code]-1]);
			} else {}
		}); */

    // setTimeout(function(){
    // 	me.demoData();
    // },10000);
  },
  beforeDestroy: function () {
    // this.socket.deleteListener(this);
    //EventBus.$off('tunnelUpdate');
    EventBus.$off("AlarmToStateLinkCode");

    if (this.initTimer != null) clearTimeout(this.initTimer);

    // 사운드 timer 삭제
    // for(var i=0; i < this.pildong_sound_list.length; i++ ){
    // 	if(this.pildong_sound_list[i].timer != null)
    // 		clearTimeout(this.pildong_sound_list[i].timer);
    // }
    // for(var j=0; j < this.hannamdong_sound_list.length; j++ ){
    // 	if(this.hannamdong_sound_list[j].timer != null)
    // 		clearTimeout(this.hannamdong_sound_list[j].timer);
    // }

    for (var i = 0; i < this.bundang_sound_list.length; i++) {
      if (this.bundang_sound_list[i].timer != null)
        clearTimeout(this.bundang_sound_list[i].timer);
    }
    for (var j = 0; j < this.seoul_sound_list.length; j++) {
      if (this.seoul_sound_list[j].timer != null)
        clearTimeout(this.seoul_sound_list[j].timer);
    }
  },

  computed: {
    state_div_big() {
      this.stateDivBig =
        this.selected_tunnel_code == "DBS"
          ? "dbs_state_div_big state_content"
          : "state_div_big state_content";
      return this.stateDivBig;
    },
  },
};
</script>
<style lang="scss">
.dashboard_state_panel {
  width: 100%;
  height: 100%;
  display: inline-flex;
  margin-top: 20px;
}
.state-container {
  height: 100%;
}
.state_content {
  background: rgba(44, 44, 44, 1) 0% 0% no-repeat padding-box;
  box-shadow: 5px 10px 22px #00000029;
  border-radius: 3px;
  opacity: 1;
  width: 100%;
  height: 100%;
  padding: 10px;
  // padding: 1%;
}
.state_title {
  text-align: left;
  font-size: calc(1rem);
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  vertical-align: middle;
}
.tunnel_content {
  border: 2px solid #30384e;
  border-radius: 3px;
  opacity: 1;
  height: 75%;
}
.tunnel_title {
  text-align: left;
  font: normal normal 300 12px/18px Noto Sans KR;
  letter-spacing: 0px;
  color: #a8a8a8;
  opacity: 1;
  margin-top: 0.2vw;
}
.float_right {
  float: right;
}
.float_left {
  float: left;
}
.state_font_big {
  text-align: left;
  font-size: calc(1.8rem);
  letter-spacing: 0px;
  color: #f8f8fb;
  opacity: 1;
  margin-bottom: 1px;
}
.state_font_small {
  text-align: left;
  font-size: calc(0.8rem);
  letter-spacing: 0px;
  color: #f8f8fb;
  opacity: 1;
}
.state_div_big {
  border: 2px solid rgba(57, 57, 57, 1);
  height: 130%;
  width: 55%;
  // text-align: right;
  text-align: center;
  border-radius: 5px;
}
.dbs_state_div_big {
  border: 2px solid rgba(57, 57, 57, 1);
  height: 130%;
  width: 70%;
  text-align: right;
  border-radius: 5px;
}
.state_div_big_r {
  border: 2px solid rgba(57, 57, 57, 1);
  height: 130%;
  width: 24%;
  border-radius: 5px;
}
.state_div_mid {
  height: 100%;
  width: 18%;
}
.state_div_small {
  border: 2px solid rgba(57, 57, 57, 1);
  height: 130%;
  width: 10%;
  border-radius: 5px;
}
.state_div_top {
  height: 60%;
  width: 90%;
}
.state_div_top_r {
  height: 30%;
  width: 100%;
  padding-left: 0.5vw;
  padding-right: 0.3vw;
  margin-top: -0.2vw;
}
.state_div_bot {
  height: 50%;
  width: 100%;
  padding-top: 0.1vw;
  margin-left: 11px;
}
.state_div_bot_cl {
  height: 50%;
  width: 100%;
  padding-top: 0.1vw;
  margin-left: 1px;
}
.state_div_bot_r {
  height: 50%;
  width: 100%;
  padding-left: 0.5vw;
  padding-right: 0.3vw;
  padding-top: 0.1vw;
}
.state_div_bot_contents {
  height: 100%;
  width: 50%;
}
.state_space {
  width: 0.3%;
}
.red_ball {
  width: 0.4vw;
  margin-right: 0.2vw;
}
.green_ball {
  width: 0.4vw;
  margin-right: 0.2vw;
}
.selectTunnel .outer-rect {
  stroke: #e64359;
  fill: rgba(230, 66, 88, 0.3);
}
.selectTunnel .inner-background {
  stroke: #e64359;
  fill: rgba(230, 66, 88, 0.3);
}
.selectTunnel .hatch {
  fill: url(#selectHatch);
}
#selectHatch rect {
  fill: #e64359;
}
.hoverTunnel {
  cursor: pointer;
}
.hoverTunnel .outer-rect {
  stroke: #e64359;
  fill: rgba(230, 66, 88, 0.3);
}
.hoverTunnel .inner-background {
  stroke: #e64359;
  fill: rgba(230, 66, 88, 0.3);
}
.hoverTunnel .hatch {
  fill: url(#hoverHatch);
}
#hoverHatch rect {
  fill: #e64359;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.sound-switch-button {
  background-color: #282e40;
  border-radius: 5px;
  width: 4.5vw;
  height: 1.8vh;
  margin-bottom: 0.2vh;
  text-align: center;
  color: white;
  font: normal normal normal 0.65rem Noto Sans KR;
}
.sound-switch-button-click {
  background-color: #a8a8a8 !important;
}
.show-sound-ball {
  display: flex !important;
}
.select-sound-page {
  background-color: white !important;
  height: 8px !important;
}
.unselect-sound-page {
  background-color: gray;
  border-radius: 5px;
  width: 5px;
  height: 5px;
}
.sound-text-margin {
  margin-left: 0.9vw;
}
.sound-waring-ball {
  border: 1px solid red;
  width: 4px;
  height: 5px;
  margin: 0.3vh 0.3vw;
  float: right;
  position: relative;
  border-radius: 50%;
  background: red;
  display: none;
}
.pildong-animation {
  float: left;
  display: inline-block;
  position: fixed;
  top: 8vh;
  clip-path: inset(0px 0px 2vh);
  align-items: center;
  overflow: hidden;
}
.hannamdong-animation {
  float: left;
  display: inline-block;
  position: fixed;
  top: 8vh;
  clip-path: inset(0px 0px 6vh);
  align-items: center;
  overflow: hidden;
}
.overMode {
  fill: #a7abb4;
}
.mouseAera {
  cursor: pointer;
  width: 20px;
  display: block;
}
.state_div_top_top {
  height: 100%;
  width: 1%;
}
.state_nomal {
  color: rgba(78, 157, 254, 1);
  font-size: calc(0.5rem);
}
.state_div_namal {
  margin-top: 0.3vw;
}
.state_error {
  color: rgba(220, 58, 100, 1);
  padding-bottom: 1vw;
  font-size: calc(0.5rem);
}
.state_font_size {
  // font-size: calc(1.25rem);
  font-size: calc(1.45rem);
  color: rgba(255, 255, 255, 1);
}
.font_size_small {
  color: rgba(113, 113, 113, 1);
  font-size: calc(0.6rem);
}
.state_ball {
  width: 0.3vw;
  margin-top: -0.5vw;
}
.nomal-padding-custom {
  display: inline-block;
  width: 70px;
  font-size: calc(1rem);
}
.number-custom {
  display: inline-block;
  // width: 20px;
  width: 50px;
  text-align: right;
}
.text-custom {
  display: inline-block;
  width: 25px;
  text-align: left;
}
.tunnel_title {
  text-align: left;
  font: normal normal 300 12px/18px Noto Sans KR;
  letter-spacing: 0px;
  color: #a8a8a8;
  opacity: 1;
  margin-top: 0.2vw;
}
.state_font_size_ec {
  // font-size: calc(1.25rem);
  font-size: calc(1.7rem);
  color: rgba(255, 255, 255, 1);
}
</style>
