<template>
  <div>
    <b-modal
      id="modal-scrollable"
      :title="$t('label.L0162')"
      variant="dark"
      scrollable
      size="lg"
      ref="data"
      :ok-title="$t('button.B0010')"
      :cancel-title="$t('button.B0005')"
      @ok="onBtnOk"
      @cancel="onBtnCancel"
      hideHeaderClose
      no-close-on-backdrop
      no-close-on-esc
      :content-class="{ 'dark-custom': darkCheck }"
      :header-class="{ 'dark-header-custom': darkCheck }"
      body-class="bodyCustomSetting"
      footer-class="footerCustomSetting"
    >
      <b-tabs>
        <b-tab @click="tabIndex == 0">
          <template #title>
            <feather-icon icon="ServerIcon" />
            <span>{{ $t("label.L0124") }}</span>
          </template>
          <b-form>
            <fieldset class="sectionFieldset form-group" style="border:3px solid #939399 !important;">
              <legend class="span-1">{{ $t("label.L0026") }}</legend>
              <div class="volume-1">
                {{ $t("label.L0105") }} ({{ Math.floor(jam * 100) }}%)
              </div>
              <b-form-input
                class="volume-1"
                id="range-2"
                v-model="jam"
                type="range"
                min="0"
                max="1"
                step="0.01"
              ></b-form-input>
              <div class="autoChange-1" style="top: 175px;">
                {{ $t("label.L0125") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="jamAuto = true"
                      :pressed="jamAuto == true ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="jamAuto = false"
                      :pressed="jamAuto == false ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
              <div class="popupDisplay-1" style="top: 175px;">
                {{ $t("label.L0128") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="jamPopupCancle = true"
                      :pressed="jamPopupCancle == true ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="jamPopupCancle = false"
                      :pressed="jamPopupCancle == false ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset class="sectionFieldset form-group" style="border:3px solid #939399 !important;">
              <legend class="span-1">{{ $t("label.L0025") }}</legend>
              <div class="volume-1">
                {{ $t("label.L0105") }} ({{ Math.floor(sudden_stop * 100) }}%)
              </div>
              <b-form-input
                class="volume-1"
                id="range-2"
                v-model="sudden_stop"
                type="range"
                min="0"
                max="1"
                step="0.01"
              ></b-form-input>
              <div class="autoChange-1" style="top: 78px;">
                {{ $t("label.L0125") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="suddenStopAuto = true"
                      :pressed="suddenStopAuto == true ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="suddenStopAuto = false"
                      :pressed="suddenStopAuto == false ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
              <div class="popupDisplay-1" style="top: 78px;">
                {{ $t("label.L0128") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="suddenStopPopupCancle = true"
                      :pressed="suddenStopPopupCancle == true ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="suddenStopPopupCancle = false"
                      :pressed="suddenStopPopupCancle == false ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset class="sectionFieldset form-group" style="border:3px solid #939399 !important;">
              <legend class="span-1">{{ $t("label.L0027") }}</legend>
              <div class="volume-1">
                {{ $t("label.L0105") }} ({{
                  Math.floor(stoppedvehicleVal * 100)
                }}%)
              </div>
              <b-form-input
                class="volume-1"
                id="range-2"
                v-model="stoppedvehicleVal"
                type="range"
                min="0"
                max="1"
                step="0.01"
              ></b-form-input>
              <div class="autoChange-1" style="top: 275px;">
                {{ $t("label.L0125") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="stopVehicleAuto = true"
                      :pressed="stopVehicleAuto == true ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="stopVehicleAuto = false"
                      :pressed="stopVehicleAuto == false ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
              <div class="popupDisplay-1" style="top: 275px;">
                {{ $t("label.L0128") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="stopVehiclePopupCancle = true"
                      :pressed="stopVehiclePopupCancle == true ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="stopVehiclePopupCancle = false"
                      :pressed="stopVehiclePopupCancle == false ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset class="sectionFieldset form-group" style="border:3px solid #939399 !important;">
              <legend class="span-1">{{ $t("label.L0023") }}</legend>
              <div class="volume-1">
                {{ $t("label.L0105") }} ({{ Math.floor(wrongway * 100) }}%)
              </div>
              <b-form-input
                class="volume-1"
                id="range-2"
                v-model="wrongway"
                type="range"
                min="0"
                max="1"
                step="0.01"
              ></b-form-input>
              <div class="autoChange-1" style="top: 373px;">
                {{ $t("label.L0125") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="wrongwayAuto = true"
                      :pressed="wrongwayAuto == true ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="wrongwayAuto = false"
                      :pressed="wrongwayAuto == false ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
              <div class="popupDisplay-1" style="top: 373px;">
                {{ $t("label.L0128") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="wrongwayPopupCancle = true"
                      :pressed="wrongwayPopupCancle == true ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="wrongwayPopupCancle = false"
                      :pressed="wrongwayPopupCancle == false ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset class="sectionFieldset form-group" style="border:3px solid #939399 !important;">
              <legend class="span-1">{{ $t("label.L0022") }}</legend>
              <div class="volume-1">
                {{ $t("label.L0105") }} ({{ Math.floor(pedestrian * 100) }}%)
              </div>
              <b-form-input
                class="volume-1"
                id="range-2"
                v-model="pedestrian"
                type="range"
                min="0"
                max="1"
                step="0.01"
              ></b-form-input>
              <div class="autoChange-1" style="top: 472px;">
                {{ $t("label.L0125") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="pedestrianAuto = true"
                      :pressed="pedestrianAuto == true ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="pedestrianAuto = false"
                      :pressed="pedestrianAuto == false ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
              <div class="popupDisplay-1" style="top: 472px;">
                {{ $t("label.L0128") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="pedestrianPopupCancle = true"
                      :pressed="pedestrianPopupCancle == true ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="pedestrianPopupCancle = false"
                      :pressed="pedestrianPopupCancle == false ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
            </fieldset>
            
            <!-- <fieldset class="sectionFieldset form-group" style="border:3px solid #939399 !important;">
              <legend class="span-1">{{ $t("label.L0024") }}</legend>
              <div class="volume-1">
                {{ $t("label.L0105") }} ({{ Math.floor(fire * 100) }}%)
              </div>
              <b-form-input
                class="volume-1"
                id="range-2"
                v-model="fire"
                type="range"
                min="0"
                max="1"
                step="0.01"
              ></b-form-input>
              <div class="autoChange-1" style="top: 473px;">
                {{ $t("label.L0125") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="fireAuto = true"
                      :pressed="fireAuto == true ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="fireAuto = false"
                      :pressed="fireAuto == false ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
              <div class="popupDisplay-1" style="top: 473px;">
                {{ $t("label.L0128") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="firePopupCancle = true"
                      :pressed="firePopupCancle == true ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="firePopupCancle = false"
                      :pressed="firePopupCancle == false ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
            </fieldset> -->
            <!-- <fieldset class="sectionFieldset form-group" style="border:3px solid #939399 !important;">
              <legend class="span-1">{{ $t("label.L0026") }}</legend>
              <div class="volume-1">
                {{ $t("label.L0105") }} ({{ Math.floor(jam * 100) }}%)
              </div>
              <b-form-input
                class="volume-1"
                id="range-2"
                v-model="jam"
                type="range"
                min="0"
                max="1"
                step="0.01"
              ></b-form-input>
              <div class="autoChange-1" style="top: 570px;">
                {{ $t("label.L0125") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="jamAuto = true"
                      :pressed="jamAuto == true ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="jamAuto = false"
                      :pressed="jamAuto == false ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
              <div class="popupDisplay-1" style="top: 570px;">
                {{ $t("label.L0128") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="jamPopupCancle = true"
                      :pressed="jamPopupCancle == true ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="jamPopupCancle = false"
                      :pressed="jamPopupCancle == false ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset class="sectionFieldset form-group" style="border:3px solid #939399 !important;">
              <legend class="span-1">{{ $t("label.L0091") }}</legend>
              <div class="volume-1">
                {{ $t("label.L0105") }} ({{
                  Math.floor(accident_sound * 100)
                }}%)
              </div>
              <b-form-input
                class="volume-1"
                id="range-2"
                v-model="accident_sound"
                type="range"
                min="0"
                max="1"
                step="0.01"
              ></b-form-input>
              <div class="autoChange-1" style="top: 667px;">
                {{ $t("label.L0125") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="accidentSoundAuto = true"
                      :pressed="accidentSoundAuto == true ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="accidentSoundAuto = false"
                      :pressed="accidentSoundAuto == false ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
              <div class="popupDisplay-1" style="top: 667px;">
                {{ $t("label.L0128") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="accidentSoundPopupCancle = true"
                      :pressed="accidentSoundPopupCancle == true ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="accidentSoundPopupCancle = false"
                      :pressed="
                        accidentSoundPopupCancle == false ? true : false
                      "
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset class="sectionFieldset form-group" style="border:3px solid #939399 !important;">
              <legend class="span-1">{{ $t("label.L0092") }}</legend>
              <div class="volume-1">
                {{ $t("label.L0105") }} ({{ Math.floor(horn_sound * 100) }}%)
              </div>
              <b-form-input
                class="volume-1"
                id="range-2"
                v-model="horn_sound"
                type="range"
                min="0"
                max="1"
                step="0.01"
              ></b-form-input>
              <div class="autoChange-1" style="top: 765px;">
                {{ $t("label.L0125") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="hornSoundAuto = true"
                      :pressed="hornSoundAuto == true ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="hornSoundAuto = false"
                      :pressed="hornSoundAuto == false ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
              <div class="popupDisplay-1" style="top: 765px;">
                {{ $t("label.L0128") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="hornSoundPopupCancle = true"
                      :pressed="hornSoundPopupCancle == true ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="hornSoundPopupCancle = false"
                      :pressed="hornSoundPopupCancle == false ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset class="sectionFieldset form-group" style="border:3px solid #939399 !important;">
              <legend class="span-1">{{ $t("label.L0093") }}</legend>
              <div class="volume-1">
                {{ $t("label.L0105") }} ({{ Math.floor(sudden_sound * 100) }}%)
              </div>
              <b-form-input
                class="volume-1"
                id="range-2"
                v-model="sudden_sound"
                type="range"
                min="0"
                max="1"
                step="0.01"
              ></b-form-input>
              <div class="autoChange-1" style="top: 862px;">
                {{ $t("label.L0125") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="suddenSoundAuto = true"
                      :pressed="suddenSoundAuto == true ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="suddenSoundAuto = false"
                      :pressed="suddenSoundAuto == false ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
              <div class="popupDisplay-1" style="top: 862px;">
                {{ $t("label.L0128") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="suddenSoundPopupCancle = true"
                      :pressed="suddenSoundPopupCancle == true ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="suddenSoundPopupCancle = false"
                      :pressed="suddenSoundPopupCancle == false ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset class="sectionFieldset form-group" style="border:3px solid #939399 !important;">
              <legend class="span-1">{{ $t("label.L0094") }}</legend>
              <div class="volume-1">
                {{ $t("label.L0105") }} ({{ Math.floor(noise * 100) }}%)
              </div>
              <b-form-input
                class="volume-1"
                id="range-2"
                v-model="noise"
                type="range"
                min="0"
                max="1"
                step="0.01"
              ></b-form-input>
              <div class="autoChange-1" style="top: 960px;">
                {{ $t("label.L0125") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="noisePopupAuto = true"
                      :pressed="noisePopupAuto == true ? true : false"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="noisePopupAuto = false"
                      :pressed="noisePopupAuto == false ? true : false"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
              <div class="popupDisplay-1" style="top: 960px;">
                {{ $t("label.L0128") }}
                <div class="autoChange-popupDisplay-1"></div>
                <b-form-group>
                  <b-button-group size="sm">
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="noisePopupCancle = true"
                      :pressed="noisePopupCancle == true ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0126") }}</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      style="
                        width: 78.17px;
                        padding-right: 0px;
                        padding-left: 0px;
                      "
                      @click="noisePopupCancle = false"
                      :pressed="noisePopupCancle == false ? true : false"
                      :disabled="isShowPopupCancle"
                      >{{ $t("label.L0127") }}</b-button
                    >
                  </b-button-group>
                </b-form-group>
              </div>
            </fieldset> -->
          </b-form>
        </b-tab>
        <b-tab @click="tabIndex == 0">
          <template #title>
            <feather-icon icon="ClockIcon" />
            <span>{{ $t("label.L0129") }}</span>
          </template>
          <b-form>
            <b-col md="12" style="padding-left: 0rem">
              <b-row>
                <b-col md="5">
                  <label> {{ $t("label.L0130") }} </label>
                </b-col>
                <b-col md="5" style="padding-top: 5px">
                  <b-form-group>
                    <b-button-group size="sm">
                      <b-button
                        variant="outline-primary"
                        style="
                          width: 78.17px;
                          padding-right: 0px;
                          padding-left: 0px;
                        "
                        @click="eventPopupKeepYn = true"
                        :pressed="eventPopupKeepYn == true ? true : false"
                        >{{ $t("label.L0126") }}</b-button
                      >
                      <b-button
                        variant="outline-primary"
                        style="
                          width: 78.17px;
                          padding-right: 0px;
                          padding-left: 0px;
                        "
                        @click="eventPopupKeepYn = false"
                        :pressed="eventPopupKeepYn == false ? true : false"
                        >{{ $t("label.L0127") }}</b-button
                      >
                    </b-button-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="5">
                  <label
                    >{{ $t("label.L0131") }} ( {{ eventPopupTimerString }} )
                  </label>
                </b-col>
                <b-col md="5">
                  <b-form-group>
                    <b-form-spinbutton
                      id="sb-inline"
                      style="color: black"
                      inline
                      :disabled="eventPopupKeepYn == true ? true : false"
                      v-model="eventPopupTimer"
                      min="5"
                      max="3600"
                      step="5"
                    ></b-form-spinbutton>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-form>
        </b-tab>
        <b-tab @click="tabIndex == 0">
          <template #title>
            <img
              src="resources/img/icons/dashboard/speedometer.svg"
              v-show="!isSpeedTab"
              style="width: 1em; margin-top: 0vw; margin-right: 0.4vw"
            />
            <img
              src="resources/img/icons/dashboard/speedometer_purple.svg"
              v-show="isSpeedTab"
              style="width: 1em; margin-top: 0vw; margin-right: 0.4vw"
            />
            <span>{{ $t("label.L0132") }}</span>
          </template>
          <b-form>
            <b-col md="12" style="padding-left: 0rem" v-if="$i18n.locale == 'ko' ">
              <b-row>
                <b-col
                  md="2"
                  style="width: 0.5rem; color: black; padding-right: 0px"
                >
                  <label
                    >{{ $t("label.L0133") }}(<span style="color: #e64359"
                      >●</span
                    >)</label
                  >
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      id="disabledInput"
                      disabled
                      class="text-center"
                      autocomplete="off"
                      value="0"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px">km/h {{ $t("label.L0033") }}</div>
                
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="delay"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px">km/h {{ $t("label.L0032") }}</div>
              </b-row>
              <b-row>
                <b-col
                  md="2"
                  style="width: 0.5rem; color: black; padding-right: 0px"
                >
                  <label
                    >{{ $t("label.L0134") }}(<span style="color: #ff8e43"
                      >●</span
                    >)</label
                  >
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="delay"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px">km/h {{ $t("label.L0033") }}</div>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="slow"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px">km/h {{ $t("label.L0032") }}</div>
              </b-row>
              <b-row>
                <b-col
                  md="2"
                  style="width: 0.5rem; color: black; padding-right: 0px"
                >
                  <label
                    >{{ $t("label.L0135") }}(<span style="color: #ffb431"
                      >●</span
                    >)</label
                  >
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="slow"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px">km/h {{ $t("label.L0033") }}</div>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="smooth"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px">km/h {{ $t("label.L0032") }}</div>
              </b-row>
              <b-row>
                <b-col
                  md="2"
                  style="width: 0.5rem; color: black; padding-right: 0px"
                >
                  <label
                    >{{ $t("label.L0136") }}(<span style="color: #2ce999"
                      >●</span
                    >)</label
                  >
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="smooth"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px">km/h {{ $t("label.L0033") }}</div>
              </b-row>
            </b-col>
            <b-col md="12" style="padding-left: 0rem" v-else>
              <b-row>
                <b-col
                  md="2"
                  style="width: 0.5rem; color: black; padding-right: 0px"
                >
                  <label
                    >{{ $t("label.L0133") }}(<span style="color: #e64359"
                      >●</span
                    >)</label
                  >
                </b-col>
                <div style="margin-top: 6px">{{ $t("label.L0033") }}</div>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      id="disabledInput"
                      disabled
                      class="text-center"
                      autocomplete="off"
                      value="0"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px">km/h</div>
                <div style="margin: 6px 0 0 6px">{{ $t("label.L0032") }}</div>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="delay"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px"> km/h</div>
              </b-row>
              <b-row>
                <b-col
                  md="2"
                  style="width: 0.5rem; color: black; padding-right: 0px"
                >
                  <label
                    >{{ $t("label.L0134") }}(<span style="color: #ff8e43"
                      >●</span
                    >)</label
                  >
                </b-col>
                <div style="margin-top: 6px">{{ $t("label.L0033") }}</div>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="delay"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px"> km/h</div>
                <div style="margin: 6px 0 0 6px">{{ $t("label.L0032") }}</div>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="slow"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px"> km/h</div>
              </b-row>
              <b-row>
                <b-col
                  md="2"
                  style="width: 0.5rem; color: black; padding-right: 0px"
                >
                  <label
                    >{{ $t("label.L0135") }}(<span style="color: #ffb431"
                      >●</span
                    >)</label
                  >
                </b-col>
                <div style="margin-top: 6px">{{ $t("label.L0033") }}</div>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="slow"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px"> km/h</div>
                <div style="margin: 6px 0 0 6px">{{ $t("label.L0032") }} </div>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="smooth"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px"> km/h</div>
              </b-row>
              <b-row>
                <b-col
                  md="2"
                  style="width: 0.5rem; color: black; padding-right: 0px"
                >
                  <label
                    >{{ $t("label.L0136") }}(<span style="color: #2ce999"
                      >●</span
                    >)</label
                  >
                </b-col>
                <div style="margin-top: 6px">{{ $t("label.L0033") }}</div>
                <b-col md="3">
                  <b-form-group>
                    <b-form-input
                      style="margin-top: 3px; color: black"
                      class="text-center"
                      autocomplete="off"
                      v-model="smooth"
                      size="sm"
                      type="number"
                      @blur="checkValue"
                    />
                  </b-form-group>
                </b-col>
                <div style="margin-top: 6px"> km/h</div>
              </b-row>
            </b-col>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {
  BContainer,
  BFormInput,
  BFormGroup,
  BForm,
  BModal,
  BFormSpinbutton,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BButton,
  BButtonGroup,
} from "bootstrap-vue";
import EventBus from "@/event-bus";
import api from "@/libs/api.js";
// import { RoadMap } from "@/components/d3/roadMap.js";

export default {
  components: {
    BContainer,
    BFormInput,
    BFormGroup,
    BForm,
    BModal,
    BFormSpinbutton,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BButton,
    BButtonGroup,
  },
  data() {
    return {
      tabIndex: 0,
      userData: JSON.parse(localStorage.getItem("userData")) || {},
      audioSoundType:
        JSON.parse(localStorage.getItem("audioSoundType")).eventType[0] || {},
      darkCheck: true,
      isSpeedTab: false,
      initializing: true, // 초기화 중임을 나타내는 플래그 추가
      isCanceling: false, // 취소 작업이 진행 중인지 나타내는 플래그 추가
      stoppedvehicleVal: null,
      pedestrian: null,
      fire: null,
      wrongway: null,
      sudden_stop: null,
      jam: null,
      accident_sound: null,
      horn_sound: null,
      sudden_sound: null,
      noise: null,
      stagnation: 0,
      delay: 0,
      slow: 0,
      smooth: 0,
      eventPopupTimer: 0, // 돌발팝업 시간 ( store데이터 )
      eventPopupKeepYn: this.$store.getters["verticalMenu/eventPopupKeepYn"], // 돌발팝업 지속 여부
      isShow: true, // {{$t("label.L0125")}} {{$t("label.L0021")}} 버튼으로 인해 다른 버튼 활성화/비활성화
      isShowPopupCancle: true, // 팝업 캔슬 {{$t("label.L0021")}} 버튼으로 인해 다른 버튼 활성화/비활성화
      allAuto: true,
      stopVehicleAuto: true,
      pedestrianAuto: true,
      wrongwayAuto: true,
      suddenStopAuto: true,
      fireAuto: true,
      jamAuto: true,
      accidentSoundAuto: true,
      hornSoundAuto: true,
      suddenSoundAuto: true,
      noisePopupAuto: true,
      stopVehiclePopupCancle: true, // {{$t("label.L0027")}} 팝업 캔슬
      pedestrianPopupCancle: true, // {{$t("label.L0022")}} 팝업 캔슬
      wrongwayPopupCancle: true, // {{$t("label.L0023")}} 팝업 캔슬
      suddenStopPopupCancle: true, // {{$t("label.L0025")}} 팝업 캔슬
      firePopupCancle: true, // {{$t("label.L0024")}} 팝업 캔슬
      jamPopupCancle: true, // {{$t("label.L0026")}} 팝업 캔슬
      accidentSoundPopupCancle: true, // {{$t("label.L0091")}}< 팝업 캔슬
      hornSoundPopupCancle: true, // {{$t("label.L0092")}}< 팝업 캔슬
      suddenSoundPopupCancle: true, // {{$t("label.L0093")}}< 팝업 캔슬
      noisePopupCancle: true, // {{$t("label.L0094")}}< 팝업 캔슬
      allPopupCancle: true, // {{$t("label.L0021")}} 팝업 캔슬
    };
  },
  watch: {
    stoppedvehicleVal() {
      if (!this.initializing && !this.isCanceling) {
        const sound_url = "resources/video/alarm_stoppedvehicle.mp3";
        const eventTypeSetting = "01";
        this.audioSoundSetting(sound_url, eventTypeSetting);
      }
    },
    pedestrian() {
      if (!this.initializing && !this.isCanceling) {
        const sound_url = "resources/video/alarm_pedestrian.mp3";
        const eventTypeSetting = "02";
        this.audioSoundSetting(sound_url, eventTypeSetting);
      }
    },
    wrongway() {
      if (!this.initializing && !this.isCanceling) {
        const sound_url = "resources/video/alarm_wrongway.mp3";
        const eventTypeSetting = "03";
        this.audioSoundSetting(sound_url, eventTypeSetting);
      }
    },
    sudden_stop() {
      if (!this.initializing && !this.isCanceling) {
        const sound_url = "resources/video/alarm_sudden_stop.mp3";
        const eventTypeSetting = "04";
        this.audioSoundSetting(sound_url, eventTypeSetting);
      }
    },
    fire() {
      if (!this.initializing && !this.isCanceling) {
        const sound_url = "resources/video/alarm_fire.mp3";
        const eventTypeSetting = "06";
        this.audioSoundSetting(sound_url, eventTypeSetting);
      }
    },
    jam() {
      if (!this.initializing && !this.isCanceling) {
        const sound_url = "resources/video/alarm_jam.mp3";
        const eventTypeSetting = "09";
        this.audioSoundSetting(sound_url, eventTypeSetting);
      }
    },
    accident_sound() {
      if (!this.initializing && !this.isCanceling) {
        const sound_url = "resources/video/alarm_accident_sound_11.m4a";
        const eventTypeSetting = "11";
        this.audioSoundSetting(sound_url, eventTypeSetting);
      }
    },
    horn_sound() {
      if (!this.initializing && !this.isCanceling) {
        const sound_url = "resources/video/alarm_horn_sound_12.m4a";
        const eventTypeSetting = "12";
        this.audioSoundSetting(sound_url, eventTypeSetting);
      }
    },
    sudden_sound() {
      if (!this.initializing && !this.isCanceling) {
        const sound_url = "resources/video/alarm_sudden_sound_13.m4a";
        const eventTypeSetting = "13";
        this.audioSoundSetting(sound_url, eventTypeSetting);
      }
    },
    noise() {
      if (!this.initializing && !this.isCanceling) {
        const sound_url = "resources/video/alarm_noise_14.m4a";
        const eventTypeSetting = "14";
        this.audioSoundSetting(sound_url, eventTypeSetting);
      }
    },
    allAuto() {
      if (!this.initializing) {
        this.isShow = this.allAuto;
      }
    },
    allPopupCancle() {
      if (!this.initializing) {
        this.isShowPopupCancle = this.allPopupCancle;
      }
    },
    tabIndex() {
      if (this.tabIndex == 0) {
        this.isSpeedTab = true;
      } else this.isSpeedTab = false;
    },
  },
  computed: {
    // 알람설정 유지시간 분,초로 표현하기 위해
    eventPopupTimerString() {
      let fullTimer = this.eventPopupTimer;
      let minut = 0; // 분
      let second = 0; // 초
      let text = "";

      var minutdName = this.$t("label.L0160");
      var secondName = this.$t("label.L0161");

      if (fullTimer < 60) {
        // 60초 보다 작은경우 '초' 만 표시
        text = fullTimer + " " + secondName + " ";
      } else {
        minut = Math.floor(fullTimer / 60);
        second = fullTimer % 60;
        text = minut + minutdName + " " + second + " " + secondName;
      }

      return text;
    },
  },
  methods: {
    init() {
      this.stoppedvehicleVal = this.audioSoundType.stoppedvehicleVal;
      this.pedestrian = this.audioSoundType.pedestrian;
      this.wrongway = this.audioSoundType.wrongway;
      this.sudden_stop = this.audioSoundType.sudden_stop;
      this.fire = this.audioSoundType.fire;
      this.jam = this.audioSoundType.jam;
      this.accident_sound = this.audioSoundType.accident_sound;
      this.horn_sound = this.audioSoundType.horn_sound;
      this.sudden_sound = this.audioSoundType.sudden_sound;
      this.noise = this.audioSoundType.noise;
      this.eventPopupTimer =
        this.$store.getters["verticalMenu/adminUser"].eventPopupTimer;
      this.getSpeedColor();
    },
    async cancelInit() {
      this.stoppedvehicleVal = this.audioSoundType.stoppedvehicleVal;
      this.pedestrian = this.audioSoundType.pedestrian;
      this.wrongway = this.audioSoundType.wrongway;
      this.sudden_stop = this.audioSoundType.sudden_stop;
      this.fire = this.audioSoundType.fire;
      this.jam = this.audioSoundType.jam;
      this.accident_sound = this.audioSoundType.accident_sound;
      this.horn_sound = this.audioSoundType.horn_sound;
      this.sudden_sound = this.audioSoundType.sudden_sound;
      this.noise = this.audioSoundType.noise;
      this.audioSoundSetting(null, null);
    },
    async onBtnOk() {
      // 볼륨세팅은 이미 앞선 로직에서 로컬스토리지에 값을 저장했기 때문에 저장에 관한 활동 없음
      // 로컬스토리지에 최신으로 저장한 값으로 this.audioSoundType 값만 재 세팅
      // 돌발설정({{$t("label.L0105")}})
      this.audioSoundType =
        JSON.parse(localStorage.getItem("audioSoundType")).eventType[0] || {};
      // 돌발팝업 지속 여부 판별
      if (this.eventPopupKeepYn) {
        // true 인 경우 팝업 없어지지 않음
        //알람설정(유지시간)
        this.eventPopupTimer =
          this.$store.getters["verticalMenu/adminUser"].eventPopupTimer; // store값으로 값 롤백
      }

      // 차량속도(정제/지체/서행/원활 속도 설정)
      var updateSpeed =
        this.stagnation +
        ", " +
        this.delay +
        ", " +
        this.slow +
        ", " +
        this.smooth;

      // 돌발설정({{$t("label.L0125")}})
      var updateAutoChangeEvent = "";
      if (this.allAuto) {
        updateAutoChangeEvent += "1, 2, 3, 4, 6, 9, 12, 12, 13, 14, 99";
        this.stopVehicleAuto = true;
        this.pedestrianAuto = true;
        this.wrongwayAuto = true;
        this.suddenStopAuto = true;
        this.fireAuto = true;
        this.jamAuto = true;
        this.accidentSoundAuto = true;
        this.hornSoundAuto = true;
        this.suddenSoundAuto = true;
        this.noisePopupAuto = true;
      } else {
        if (this.stopVehicleAuto) {
          //{{$t("label.L0027")}}
          updateAutoChangeEvent += "1, ";
        }
        if (this.pedestrianAuto) {
          //{{$t("label.L0022")}}
          updateAutoChangeEvent += "2, ";
        }
        if (this.wrongwayAuto) {
          //{{$t("label.L0023")}}
          updateAutoChangeEvent += "3, ";
        }
        if (this.suddenStopAuto) {
          //{{$t("label.L0025")}}
          updateAutoChangeEvent += "4, ";
        }
        if (this.fireAuto) {
          //{{$t("label.L0024")}}
          updateAutoChangeEvent += "6, ";
        }
        if (this.jamAuto) {
          //{{$t("label.L0026")}}
          updateAutoChangeEvent += "9, ";
        }
        if (this.accidentSoundAuto) {
          //{{$t("label.L0091")}}<
          updateAutoChangeEvent += "11, ";
        }
        if (this.hornSoundAuto) {
          //{{$t("label.L0092")}}<
          updateAutoChangeEvent += "12, ";
        }
        if (this.suddenSoundAuto) {
          //{{$t("label.L0093")}}<
          updateAutoChangeEvent += "13, ";
        }
        if (this.noisePopupAuto) {
          //{{$t("label.L0094")}}<
          updateAutoChangeEvent += "14, ";
        }
        // 마지막에 추가된 ", "를 제거
        if (updateAutoChangeEvent.endsWith(", ")) {
          updateAutoChangeEvent = updateAutoChangeEvent.slice(0, -2);
        }
      }

      // 돌발설정({{$t("label.L0128")}})
      var updatePopupCancleEvent = "";
      if (this.allPopupCancle) {
        // updatePopupCancleEvent += "99";
        // updatePopupCancleEvent += '01, 04, 09, 11, 12, 13, 14, 99';
        this.stopVehiclePopupCancle = true; // {{$t("label.L0027")}} 팝업 캔슬
        this.pedestrianPopupCancle = true; // {{$t("label.L0022")}} 팝업 캔슬
        this.wrongwayPopupCancle = true; // {{$t("label.L0023")}} 팝업 캔슬
        this.suddenStopPopupCancle = true; // {{$t("label.L0025")}} 팝업 캔슬
        this.firePopupCancle = true; // {{$t("label.L0024")}} 팝업 캔슬
        this.jamPopupCancle = true; // {{$t("label.L0026")}} 팝업 캔슬
        this.accidentSoundPopupCancle = true; // {{$t("label.L0091")}}< 팝업 캔슬
        this.hornSoundPopupCancle = true; // {{$t("label.L0092")}}< 팝업 캔슬
        this.suddenSoundPopupCancle = true; // {{$t("label.L0093")}}< 팝업 캔슬
        this.noisePopupCancle = true; // {{$t("label.L0094")}}< 팝업 캔슬
        this.allPopupCancle = true; // {{$t("label.L0021")}} 팝업 캔슬
      } else {
        if (!this.stopVehiclePopupCancle) {
          updatePopupCancleEvent += "1, ";
        }
        if (!this.pedestrianPopupCancle) {
          updatePopupCancleEvent += "2, ";
        }
        if (!this.wrongwayPopupCancle) {
          updatePopupCancleEvent += "3, ";
        }
        if (!this.suddenStopPopupCancle) {
          updatePopupCancleEvent += "4, ";
        }
        if (!this.firePopupCancle) {
          updatePopupCancleEvent += "6, ";
        }
        if (!this.jamPopupCancle) {
          updatePopupCancleEvent += "9, ";
        }
        if (!this.accidentSoundPopupCancle) {
          updatePopupCancleEvent += "11, ";
        }
        if (!this.hornSoundPopupCancle) {
          updatePopupCancleEvent += "12, ";
        }
        if (!this.suddenSoundPopupCancle) {
          updatePopupCancleEvent += "13, ";
        }
        if (!this.noisePopupCancle) {
          updatePopupCancleEvent += "14, ";
        }
        // 마지막에 추가된 ", "를 제거
        if (updatePopupCancleEvent.endsWith(", ")) {
          updatePopupCancleEvent = updatePopupCancleEvent.slice(0, -2);
        }
      }

      const response = await api.modifyUserSetting({
        updateSpeed: updateSpeed,
        eventPopupTimer: Number(this.eventPopupTimer),
        updateAutoChangeEvent: updateAutoChangeEvent,
        updatePopupCancleEvent: updatePopupCancleEvent,
        userId: this.userData.userid,
      });
      response;
      // 알람 설정 지속 여부, 유지시간 response 결과값을 스토리지에 저장
      this.$store.commit(
        "verticalMenu/UPDATE_POPUP_KEEP_YN",
        this.eventPopupKeepYn
      );
      this.$store.commit(
        "verticalMenu/UPDATE_EVENT_POPUP_TIMER",
        Number(this.eventPopupTimer)
      );

      EventBus.$emit(
        "speedColorSetting",
        this.stagnation,
        this.delay,
        this.slow,
        this.smooth
      );
      EventBus.$emit(
        "autoChangeEventList",
        updateAutoChangeEvent.replace(/ /g, "").split(",")
      );
      EventBus.$emit(
        "canclePopupEventList",
        updatePopupCancleEvent.replace(/ /g, "").split(",")
      );
    },
    async onBtnCancel() {
      this.isCanceling = true; // 변경 전에 플래그 설정
      await this.cancelInit(); // 볼륨세팅 값 다시 원래대로 되돌리기
      this.isCanceling = false; // 변경 후에 플래그 재설정
    },
    audioSoundSetting(sound_url, eventTypeSetting) {
      localStorage.removeItem("audioSoundType");
      const audioSoundType = {
        eventType: [
          {
            stoppedvehicleVal: this.stoppedvehicleVal,
            pedestrian: this.pedestrian,
            wrongway: this.wrongway,
            sudden_stop: this.sudden_stop,
            fire: this.fire,
            jam: this.jam,
            accident_sound: this.accident_sound,
            horn_sound: this.horn_sound,
            sudden_sound: this.sudden_sound,
            noise: this.noise,
          },
        ],
      };
      localStorage.setItem("audioSoundType", JSON.stringify(audioSoundType));
      if (!!eventTypeSetting) {
        EventBus.$emit("audioSoundSetting", sound_url, eventTypeSetting);
      }
    },
    async getSpeedColor() {
      try {
        const response = await api.getSpeedColor(this.userData.userid);
        const { data } = response.data;
        var speedColorList = data.speedColor.replace(/ /g, "").split(",");
        this.stagnation = Number(speedColorList[0]);
        this.delay = Number(speedColorList[1]);
        this.slow = Number(speedColorList[2]);
        this.smooth = Number(speedColorList[3]);
        EventBus.$emit(
          "speedColorSetting",
          this.stagnation,
          this.delay,
          this.slow,
          this.smooth
        );
      } catch (err) {}
    },
    checkValue() {
      if (Number(this.delay) == 0 || this.delay == null || this.delay == "") {
        this.delay = Number(1);
        this.checkValue();
      } else if (
        Number(this.delay) >= Number(this.slow) ||
        this.slow == null ||
        this.slow == ""
      ) {
        this.slow = Number(this.delay) + 1;
        this.checkValue();
      } else if (
        Number(this.slow) >= Number(this.smooth) ||
        this.smooth == null ||
        this.smooth == ""
      ) {
        this.smooth = Number(this.slow) + 1;
        this.checkValue();
      }
    },
    async getAutoChangeEvent() {
      try {
        const response = await api.getAutoChangeEvent(this.userData.userid);
        const { data } = response.data;
        var autoChangeEventList = data.autoChangeEvent
          .replace(/ /g, "")
          .split(",");
        if (autoChangeEventList.includes("1")) {
          this.stopVehicleAuto = true;
        } else this.stopVehicleAuto = false;
        if (autoChangeEventList.includes("2")) {
          this.pedestrianAuto = true;
        } else this.pedestrianAuto = false;
        if (autoChangeEventList.includes("3")) {
          this.wrongwayAuto = true;
        } else this.wrongwayAuto = false;
        if (autoChangeEventList.includes("4")) {
          this.suddenStopAuto = true;
        } else this.suddenStopAuto = false;
        if (autoChangeEventList.includes("6")) {
          this.fireAuto = true;
        } else this.fireAuto = false;
        if (autoChangeEventList.includes("9")) {
          this.jamAuto = true;
        } else this.jamAuto = false;
        if (autoChangeEventList.includes("11")) {
          this.accidentSoundAuto = true;
        } else this.accidentSoundAuto = false;
        if (autoChangeEventList.includes("12")) {
          this.hornSoundAuto = true;
        } else this.hornSoundAuto = false;
        if (autoChangeEventList.includes("13")) {
          this.suddenSoundAuto = true;
        } else this.suddenSoundAuto = false;
        if (autoChangeEventList.includes("14")) {
          this.noisePopupAuto = true;
        } else this.noisePopupAuto = false;
        // if (autoChangeEventList.includes("99")) {
        //   this.allAuto = true;
        // } else this.allAuto = false;
        EventBus.$emit("autoChangeEventList", autoChangeEventList);
      } catch (err) {}
    },
    async getPopupCancleEvent() {
      try {
        const response = await api.getPopupCancleEvent(this.userData.userid);
        const { data } = response.data;
        var popupCancleEventList = data.popupCancleEvent
          .replace(/ /g, "")
          .split(",");

        // {{$t("label.L0027")}}
        if (popupCancleEventList.includes("1")) {
          this.stopVehiclePopupCancle = false;
        } else this.stopVehiclePopupCancle = true;

        // {{$t("label.L0022")}}
        if (popupCancleEventList.includes("2")) {
          this.pedestrianPopupCancle = false;
        } else this.pedestrianPopupCancle = true;

        // {{$t("label.L0023")}}
        if (popupCancleEventList.includes("3")) {
          this.wrongwayPopupCancle = false;
        } else this.wrongwayPopupCancle = true;

        // {{$t("label.L0025")}}
        if (popupCancleEventList.includes("4")) {
          this.suddenStopPopupCancle = false;
        } else this.suddenStopPopupCancle = true;

        // {{$t("label.L0024")}}
        if (popupCancleEventList.includes("6")) {
          this.firePopupCancle = false;
        } else this.firePopupCancle = true;

        // {{$t("label.L0026")}}
        if (popupCancleEventList.includes("9")) {
          this.jamPopupCancle = false;
        } else this.jamPopupCancle = true;

        // {{$t("label.L0091")}}<
        if (popupCancleEventList.includes("11")) {
          this.accidentSoundPopupCancle = false;
        } else this.accidentSoundPopupCancle = true;

        // {{$t("label.L0092")}}<
        if (popupCancleEventList.includes("12")) {
          this.hornSoundPopupCancle = false;
        } else this.hornSoundPopupCancle = true;

        // {{$t("label.L0093")}}<
        if (popupCancleEventList.includes("13")) {
          this.suddenSoundPopupCancle = false;
        } else this.suddenSoundPopupCancle = true;

        // {{$t("label.L0094")}}<
        if (popupCancleEventList.includes("14")) {
          this.noisePopupCancle = false;
        } else this.noisePopupCancle = true;

        // {{$t("label.L0021")}}
        if (popupCancleEventList.includes("99")) {
          this.allPopupCancle = true;
        } else this.allPopupCancle = false;

        EventBus.$emit("popupCancleEventList", popupCancleEventList);
      } catch (err) {}
    },
  },
  destroyed() {
    // console.log('컴포넌트 삭제 ');
  },
  async created() {
    await this.init();
    this.initializing = false; // 값 설정이 종료됨을 알리는 변수
    await this.getAutoChangeEvent();
    await this.getPopupCancleEvent();
  },
};
</script>

<style scoped>
/* // #modal-scrollable .modal-dialog{
  //   max-width: 600px;
  // }  */
/* .modal-dialog .modal-dialog-scrollable{
    max-width: 600px !important;
  }  */
/* .modal-dialog .modal-dialog-scrollable {
    max-width: 600px !important;
  } */
/* .modal-scrollable-custom .modal-dialog {
    max-width: 700px;
  } */
.modal .modal-600 {
  max-width: 700px;
}
.dark-custom {
  background-color: #2f3134 !important;
  color: white !important;
}
.dark-custom label {
  color: white !important;
}
.dark-header-custom {
  background-color: #3c3d41 !important;
}
.dark-header-custom h5 {
  color: white !important;
}
.custom-text {
  background-color: #3c3d41 !important;
}
.dark-text {
  color: white;
}
.dark-custom .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.sectionFieldset {
  border: solid 1px rgba(255, 255, 255, 0.125);
  padding-top: 0px;
  padding-right: 12px;
  padding-bottom: 15px;
  padding-left: 12px;
}
.sectionFieldset legend {
  width: inherit;
  /* Or auto */
  padding: 0 10px;
  /* To give a bit of padding on the left and right */
  border-bottom: none;
  font-weight: bold;
  font-size: 15px;
}
.sectionFieldset .custom-control-inline {
  margin-right: 2rem;
}
label {
  padding-top: 6px;
  color: #5e5873;
  font-size: 14px;
}
.bodyCustomSetting {
  padding: 1rem 1.4rem 0.2rem 1.4rem;
}
.footerCustomSetting {
  border-top-width: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 17px !important;
}

.div-1 {
  position: relative;
  border: 3px solid #939399;
  padding: 0.8rem;
}
.span-1 {
  font-weight: bold;
  font-size: medium;
  /* width: 10%; */
  margin: 0;
  text-align: -webkit-center;
}
.volume-1 {
  width: 50%;
}
.autoChange-1 {
  width: 25%;
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 0.5rem;
  padding-left: 2em;
  z-index: 2;
}
.autoChange-popupDisplay-1 {
  margin-top: 0.4rem;
}
.popupDisplay-1 {
  width: 50%;
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 0.5rem;
  padding-left: 15em;
  z-index: 1;
}
.autoChange-popupDisplay-1 {
  margin-top: 0.4rem;
}
</style>
