<template>
  <div class="dashboard_rtd_panel" id="main">
    <div id="roadMap" class="rtd_div" :class="{ road_show: isShow }">
      <div class="map-button-group"></div>
      <div class="equip-tooltip">
        <div>{{ tooltip.data.equipId }}</div>
      </div>
    </div>
    <div class="tooltip-box" id="tooltip">
      <div class="icon"><img src="../../../../assets/construction.svg" /></div>
      <div class="text">
        {{ $t("label.L0072") }} : {{ scheduleName }}<br />
        {{ $t("label.L0073") }} : {{ startConstruction }}k<br />
        {{ $t("label.L0074") }} : {{ endConstruction }}k
      </div>
    </div>
  </div>
</template>

<script>
import { RoadMap } from "@/components/d3/roadMap.js";
import { getToggle } from "@/components/utils/dataConnet.js";
import EventBus from "@/event-bus";
export default {
  name: "RealTimeDrivingRadar",
  components: {},
  data: function () {
    return {
      selectTunnelCode: null,
      roadMap: null,
      equipList: null,
      tunnelTapInfo: null,
      isShow: true,
      eventQueue: [],
      scheduleList: [],
      scheduleTimer: null,
      soundDeviceList: [],
      tooltip: {
        data: {},
      },
      constructionPosition: [{ key: "0.0", start: 33, width: 118 }],
      toggle: false,
      scheduleName: "", // 일정명
      startConstruction: "",
      endConstruction: "",
      isShowTootip: false,
      isFullScreen: false,
      toggleSub: null,
      timesocket: null,
      upTimesocket: null,
      downTimesocket: null,
      timeStateSocket: null,
      scheduleDraw: {
        initwidth:
          this.$store.getters["settingConfig/getTarget"]("gs").scheduleDraw
            .initwidth,
        startDraw:
          this.$store.getters["settingConfig/getTarget"]("gs").scheduleDraw
            .startDraw,
      },
      selectTunnelPort: {
        up: "",
        down: "",
      },
      endposition: null,
      allEquipList: [],
      demoEventTimer: null,
    };
  },
  methods: {
    showConstructionTootip: function (event) {
      if (!!event["target"]["dataset"]) {
        this.startConstruction = event["target"]["dataset"]["start"];
        this.endConstruction = event["target"]["dataset"]["end"];
        this.scheduleName = event["target"]["dataset"]["scheduleName"];
      }

      const box = event.target.id ? event.target : event.target.parentNode;
      const tooltip = document.getElementById("tooltip");

      if (!!tooltip) {
        box.appendChild(tooltip);
        tooltip.style.visibility = "visible";
      }
    },

    hideConstructionTootip: function () {
      const tooltip = document.getElementById("tooltip");
      if (!!tooltip) {
        tooltip.style.visibility = "hidden";
      }
    },

    underConstruction: function (redraw) {
      // 공사이정 너비 조절
      if (this.scheduleList.length > 0) {
        // scheduleList 의 길이가 0보다 클 때 실행 (표시해야 할 일정이 있는 경우)
        const dashboardEle = document.getElementById("main"); // 'main' id를 가진 엘리먼트를 찾아 dashboardEle에 할당
        for (var i = 0; i < this.scheduleList.length; i++) {
          // scheduleList 배열을 순회하는 반복문
          var value = this.scheduleList[i]; // 현재 인덱스의 scheduleList 요소를 value에 할당

          // 공사이정 존재 유무 채크
          var ele2 = document.getElementsByClassName("construction" + value.no); // 공사번호를 이용해 element 생성
          if (ele2.length == 0) {
            // 공사이정 생성
            EventBus.$emit("drawConstruction", value);
            // let scheduleGroup = this.roadMap.drawSchedule(value);
          } else {
            // 공사이정 존재시 {{ $t("button.B0009") }}으로 넘어감
            continue;
          }

          // var ele = document.getElementById("construction" + value.no); // 공사번호를 이용해 element 생성
          // if (ele !== null) continue; // element가 존재하면 div element 재생성하지 않음

          // // element가 없을 때, 새로운 div element 생성
          // const newElement = document.createElement('div'); // 새로운 div 엘리먼트 생성
          // newElement.id = "construction" + value.no; // 새로운 엘리먼트의 id를 'construction' + value.no로 설정
          // newElement.dataset.start = (+value.startPosition).toFixed(2); // 시작 위치 (+ 기호를 통해 숫자로 형 변환 후 다시 소수점 이하 둘째 자리의 문자열로 변환)
          // newElement.dataset.end = (+value.endPosition).toFixed(2); // 종료 위치 (+ 기호를 통해 숫자로 형 변환 후 다시 소수점 이하 둘째 자리의 문자열로 변환)
          // newElement.dataset.scheduleName = value.scheduleName;

          // // 공사이정에 마우스 오버시 실행
          // newElement.addEventListener('mouseenter', (event) => {
          // 	this.showConstructionTootip(event);
          // });

          // // 공사이정에서 마우스 포커스아웃시 실행
          // newElement.addEventListener('mouseleave', (event) => {
          // 	this.hideConstructionTootip(event);
          // });

          // const img = document.createElement('div'); // 새로운 div 엘리먼트 생성 (공사이정에 표시될 아이콘)
          // img.className = "img"; // 클래스 이름 설정
          // img.dataset.start = value.startPosition;
          // img.dataset.end = value.endPosition;
          // img.dataset.scheduleName = value.scheduleName;
          // newElement.appendChild(img); // img를 newElement의 자식으로 추가

          // const bound = value['bound'] === '1' ? 'up' : 'down';

          // newElement.className = 'underConstruction-box ' + bound; // 클래스 이름 설정
          // const start = value['startPosition'] * this.endposition;
          // const end = value['endPosition'] * this.endposition;

          // // 너비 route.json 용
          // let width = 0; // 초기 너비
          // // let startDraw = this.scheduleDraw.startDraw; // 공사이정 표시 시작
          // let startDraw = 0.95; // 공사이정 표시 시작 위치
          // let diff = 0; // 종료이정 - 시작이정 거리 차이
          // // const initWidth = this.scheduleDraw.initwidth; // 공사이정 너비
          // const initWidth = 1.2; // 공사이정 너비

          // if ((end - start) > 0) {
          // 	diff = end - start;
          // 	width = initWidth * diff;
          // 	// console.log("start:::",start);
          // 	// console.log("end:::",end);
          // 	for (let index = 1; index <= start; index++) {
          // 		startDraw = startDraw + initWidth;
          // 		// start = start + 0.1 * index
          // 		// startDraw = startDraw + initwidth;
          // 		// left = left + initwidth;
          // 		// left = left + value['startPosition'];
          // 	}
          // } else {
          // 	// console.log("start222:::",start);
          // 	for (let index = 1; index <= end; index++) {
          // 		startDraw = startDraw + initWidth;
          // 		// left = left + initwidth;
          // 		// left = left + value['startPosition'];
          // 	}
          // }

          // // 상행의 기준은 오른쪽부터 0, 하행은 왼쪽부터 0
          // bound === 'up' ? newElement.style.right = startDraw + '%' : newElement.style.left = startDraw + '%'; // 상행 또는 하행에 따라 right 또는 left 속성 설정
          // newElement.style.width = width + '%'; // 너비 속성 설정
          // dashboardEle.appendChild(newElement);  // dashboardEle에 새로운 엘리먼트 추가
        }
      }
    },

    removeConstruction: function (schedule) {
      const mainEl = document.getElementById("main"); // tooltip이 특정 일정의 자식 요소로 추가되기 전 원래 생성되어 있던 위치
      const tooltip = document.getElementById("tooltip");
      const el = document.getElementById("construction" + schedule.no); // 삭제하려는 일정
      const elToolTip = document.getElementById(
        "constructionImg" + schedule.no
      ); // 삭제하려는 일정
      mainEl.appendChild(tooltip); // tooltip을 id가 main 요소의 자식으로 이동
      if (!!el) {
        el.parentNode.removeChild(el); // 일정 그라데이션 삭제
      }
      if (!!elToolTip) {
        elToolTip.parentNode.removeChild(elToolTip); // 일정 이미지 삭제
      }
    },

    changeMenu: function (menu) {
      if (menu.name == "dashboard") {
        this.isShow = true;
      } else {
        this.isShow = false;
        this.roadMap.isShow = false;
        this.roadMap.removeVehicle();
      }
    },

    async getEquipList(val) {
      var vue = this;
      try {
        const response = await this.api.selectEquipDashboardList({
          tunnelCode: val,
        });
        const { data } = response.data;
        vue.equipList = data.equipList;
        vue.roadMap.equipList = vue.equipList;
        vue.roadMap.drawEquip();
      } catch (err) {}
    },

    async getTunnelVehile(val) {
      try {
        const response = await this.api.selectTunnelVehile({
          selectTunnelLinkCode: val,
        });
        // console.log("response:::",response);
      } catch (err) {
        console.log("err :::", err);
      }
    },
    // 장비 Icon 그리기 -> Demo데이터
    async demoEquipList(tunnelCode) {
      var vue = this;
      try {
        // const response = await this.api.selectEquipDashboardList();
        const response = await this.api.selectEquipDashboardList({
          tunnelCode: tunnelCode,
        });

        const { data } = response.data;
        vue.equipList = data.equipList;

        for (let equip of vue.equipList) {
          equip.state = 0;
        }
        vue.roadMap.equipList = vue.equipList;

        // getEquipList() 에서 등록된 equipGroup 삭제
        var isEmptyCheckSvg = vue.roadMap.svg.select(".equipGroup");
        if (!isEmptyCheckSvg.empty()) {
          isEmptyCheckSvg.remove();
        }

        // 삭제 후 새로 equipGroup 등록
        vue.roadMap.drawEquip();
      } catch (err) {}
    },

    demoData: function (tunnelCode) {
      var me = this;

      // 차량 평균속도 demoData 호출
      EventBus.$emit("demoSpeedData");
      // 장비상태 demoData 호출
      EventBus.$emit("demoStateData", tunnelCode);
      // // 장비상태 Demodata Icon 표시
      this.demoEquipList(tunnelCode);

      // interval 삭제
      if (me.upTimesocket != null || me.downTimesocket != null) {
        clearInterval(me.upTimesocket);
        clearInterval(me.downTimesocket);
        me.downTimesocket = null;
        me.upTimesocket = null;
      }

      var selectTunnelInfo = me.tunnelTapInfo[tunnelCode];

      // console.log("======= 터널 변경 ==========");
      // console.log(selectTunnelInfo);

      // 기존 데모데이터 살리기
      // 차량 데이터 vc,vc2 파라미터로 전달하기 백대, 이백대, 삼백대 등등
      var roadMap = this.roadMap;
      let upSampleId = 0;
      let downSampleId = 0;

      // 기존 차량 데이터 삭제
      roadMap.removeVehicle();

      var sampleData = []; // 하행 데이터
      var sampleData2 = []; // 상행 데이터

      // 도로 정보
      // var roadInfo = this.$store.getters['settingConfig/getTarget']('loadConfigJson');
      const roadInfo = selectTunnelInfo;
      var roadMaxlen =
        (roadInfo[0].endposition - roadInfo[0].startposition) * 10;

      // 차량 수
      //var vc = Math.round(roadInfo[0].endposition / 3);
      var vc = 0;

      if (roadInfo[0].endposition > 150) {
        vc = 50;
      } else {
        vc = 30;
      }

      var minSpeed = 10;
      // var maxSpeed = roadInfo[0].endposition;
      var maxSpeed = 55;

      for (let i = 0; i < roadInfo.length; i++) {
        // 하행
        if (roadInfo[i].bound == 0) {
          // 하행 ( 초기 차량 세팅 )
          for (var j = 0; j < vc; j++) {
            sampleData.push({
              id: downSampleId++,
              //y: Math.floor(Math.random() * roadMaxlen), // 차량 위치 ( 하행 도로 길이 내에서 랜덤 위치 생성 )
              y: (roadMaxlen / vc) * j,
              speed: Math.floor(Math.random() * minSpeed + maxSpeed),
              //speed: 90,
              lane: Math.floor(Math.random() * roadInfo[i].lanecnt), // 차량 그려질 차선  (ex. 2차선인경우 0또는 1로 배정 )
              eventType: 0,
            });
          }
          roadMap.updateVehicle(0, sampleData); // 매개변수 ( bound, vehicleList )

          me.downTimesocket = setInterval(function () {
            for (var l = 0; l < sampleData.length; l++) {
              var data = sampleData[l];

              // 차량의 y값을 속도에 따라 업데이트
              data.y += data.speed / 36; // 속도만큼 차량 위치 변경

              // 충돌 검사 및 lane 변경 로직
              let collision = true;
              while (collision) {
                collision = sampleData.some(
                  (item) =>
                    item.id !== data.id &&
                    Math.round(item.y, 4) === Math.round(data.y, 4) &&
                    item.lane === data.lane
                );

                if (collision) {
                  if (roadInfo[i].lanecnt == 2) {
                    if (data.lane === 0) {
                      data.lane = 1;
                    } else if (data.lane === 1) {
                      data.lane = 0;
                    }
                  } else {
                    if (data.lane === 0) {
                      data.lane = 1;
                    } else if (data.lane === 1) {
                      data.lane = 2;
                    } else if (data.lane === 2) {
                      data.lane = 1;
                    }
                  }
                  break;
                }
              }

              // 도로의 끝에 도달하면 차량을 다시 처음으로 이동
              if (data.y >= roadMaxlen) {
                sampleData.splice(l, 1);
                sampleData.push({
                  id: downSampleId++,
                  y: 0,
                  speed: Math.floor(Math.random() * minSpeed + maxSpeed), // 속도를 무작위로 설정
                  lane: Math.floor(Math.random() * 4), // lane을 무작위로 설정, 예: 0~3
                  eventType: 0,
                });
              }
            }

            roadMap.updateVehicle(0, sampleData);
          }, 100);
        } else if (roadInfo[i].bound == 1) {
          // 상행 ( 초기 차량 세팅 )
          for (var k = 0; k < vc; k++) {
            sampleData2.push({
              id: upSampleId++,
              y: Math.floor(Math.random() * roadMaxlen), // 차량 위치 ( 상행 도로 길이 내에서 랜덤 위치 생성 )
              speed: Math.floor(Math.random() * minSpeed + maxSpeed),
              lane: Math.floor(Math.random() * roadInfo[i].lanecnt), // 차량 그려질 차선  (ex. 2차선인경우 0또는 1로 배정 )
              eventType: 0,
            });
          }
          roadMap.updateVehicle(1, sampleData2);
          me.upTimesocket = setInterval(function () {
            var startTime = new Date().getTime();

            // 상행 ( 도로의 끝에 도착시 0 위치에 차량 세팅 )
            for (var h = 0; h < sampleData2.length; h++) {
              var data = sampleData2[h];

              data.y -= data.speed / 36; // 속도만큼 차량 위치 변경

              // data.speed += Math.floor(Math.random() * 15 - 7); // 속도 변화
              // data.speed =
              //   data.speed < 10 ? 10 : data.speed > 90 ? 90 : data.speed;

              // 충돌 검사 및 lane 변경 로직
              let collision = true;
              while (collision) {
                collision = sampleData2.some(
                  (item) =>
                    item.id !== data.id &&
                    Math.round(item.y, 4) === Math.round(data.y, 4) &&
                    item.lane === data.lane
                );

                if (collision) {
                  if (roadInfo[i].lanecnt == 2) {
                    if (data.lane === 0) {
                      data.lane = 1;
                    } else if (data.lane === 1) {
                      data.lane = 0;
                    }
                  } else {
                    if (data.lane === 0) {
                      data.lane = 1;
                    } else if (data.lane === 1) {
                      data.lane = 2;
                    } else if (data.lane === 2) {
                      data.lane = 1;
                    }
                  }
                  break;
                }
              }

              // //data.y -= Math.floor(Math.random() * 3000 + 1000)/1000;
              // // data.lane = Math.floor(Math.random() * 10000) > 9990 ? data.lane ^ 1 : data.lane;
              if (data.y < 0) {
                sampleData2.splice(h, 1);
                sampleData2.push({
                  id: upSampleId++,
                  y: roadMaxlen, // 출발 구간 ( 도로 끝 도착했기 때문에 0 위치에서 다시 시작 )
                  speed: Math.floor(Math.random() * minSpeed + maxSpeed),
                  lane: Math.floor(Math.random() * roadInfo[i].lanecnt),
                });
              }
            }

            roadMap.updateVehicle(1, sampleData2);
          }, 100);
        }
      }
      // me.timesocket = setInterval (function(){
      // 	// if(time >= 100 || !me.isShow) {
      // 	// 	clearInterval(interval);
      // 	// 	return;
      // 	// }
      // 	var startTime = new Date().getTime();

      // 	// 하행 ( 도로의 끝에 도착시 0 위치에 차량 세팅 )
      // 	for(var i = 0 ; i < sampleData.length; i++) {
      // 		var data = sampleData[i];
      // 		data.speed += Math.floor(Math.random() * 15 - 7); // 속도 변화
      // 		data.speed = data.speed < 10 ? 10 : data.speed > 90 ? 90 : data.speed;
      // 		data.y += data.speed   / 36; // 속도만큼 차량 위치 변경

      // 		if(data.y >= roadMaxlen) {
      // 			sampleData.splice(i,1);
      // 			sampleData.push({
      // 				id : sampleId++,
      // 				y : 0, // 출발 구간 ( 도로 끝 도착했기 때문에 0 위치에서 다시 시작 )
      // 				speed : Math.floor(Math.random() * 40 + 60),
      // 				lane : Math.floor(Math.random() * roadInfo.downLaneCnt),
      // 			});
      // 		}
      // 	}
      // 	roadMap.updateVehicle(0,sampleData);

      // 	// 상행 ( 도로의 끝에 도착시 0 위치에 차량 세팅 )
      // 	for(var i = 0 ; i < sampleData2.length; i++) {
      // 		var data = sampleData2[i];
      // 		data.speed += Math.floor(Math.random() * 15 - 7); // 속도 변화
      // 		data.speed = data.speed < 10 ? 10 : data.speed > 90 ? 90 : data.speed;
      // 		data.y -= data.speed  / 36; // 속도만큼 차량 위치 변경
      // 		//data.y -= Math.floor(Math.random() * 3000 + 1000)/1000;
      // 		// data.lane = Math.floor(Math.random() * 10000) > 9990 ? data.lane ^ 1 : data.lane;
      // 		if(data.y < 0) {
      // 			sampleData2.splice(i,1);
      // 			sampleData2.push({
      // 				id : sampleId++,
      // 				y : roadMaxlen, // 출발 구간 ( 도로 끝 도착했기 때문에 0 위치에서 다시 시작 )
      // 				speed : Math.floor(Math.random() * 40 + 60),
      // 				lane : Math.floor(Math.random() * roadInfo.upLaneCnt),
      // 			});
      // 		}
      // 	}

      // 	roadMap.updateVehicle(1,sampleData2);
      // },100)
    },
    // demoEvent: function(data) {
    // 	var me = this;
    // 	var tempArr = [
    // 		{ video: 'http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16052279.STOP.287m', img: 'http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16052279.STOP.287m.jpg'},
    // 		{ video: 'http://211.229.157.247:9079/smart-ISD/record?date=2020-12-07&event=16051127.STOP.41m', img: 'http://211.229.157.247:9079/smart-ISD/record?date=2020-12-07&event=16051127.STOP.41m.jpg'},
    // 		{ video: 'http://211.229.157.247:9079/smart-ISD/record?date=2020-12-07&event=16045930.STOP.33m', img: 'http://211.229.157.247:9079/smart-ISD/record?date=2020-12-07&event=16045930.STOP.33m.jpg'},
    // 		{ video: 'http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16044467.PEDESTRIAN.83m', img: 'http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16044467.PEDESTRIAN.83m.jpg'},
    // 		{ video: 'http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16035741.PEDESTRIAN.123m', img: 'http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16035741.PEDESTRIAN.123m.jpg'},
    // 		{ video: 'http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16031004.PEDESTRIAN.58m', img: 'http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16031004.PEDESTRIAN.58m.jpg'},
    // 		{ video: 'http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16030556.PEDESTRIAN.89m', img: 'http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16030556.PEDESTRIAN.89m.jpg'},
    // 	]

    // 	setTimeout(function() {
    // 		var refreshTime = Math.floor(Math.random() * 18000 + 7000);
    // 		refreshTime = 5000;
    // 		// this.timeStateSocket = setInterval (function(){
    // 			refreshTime = Math.floor(Math.random() * 1 + 7000);
    // 			var tempUrl = tempArr[Math.floor(Math.random() * tempArr.length)];
    // 			// var equip = me.equipList[Math.floor(Math.random() * me.equipList.length)];
    // 			var date = new Date();
    // 			var eventInfo = {
    // 				OccurTime: date.getFullYear()+''+((date.getMonth()+1)<10 ?'0'+(date.getMonth()+1):date.getMonth()+1 )+''+date.getDate()+''+date.getHours()+''+date.getMinutes()+''+date.getSeconds(),
    // 				CameraID: data.eventEquip,//equip.cameraId.trim(),
    // 				DEventType: data.eventType,
    // 				RecVideoUrl: tempUrl.video,
    // 				RecImageUrl: tempUrl.img,
    // 				E_Longitude: 0,
    // 				E_Latitude: 0,
    // 				Position: data.eventPosition,//parseFloat((equip.position + (Math.floor(Math.random() * 1000)/10000)).toFixed(2)) ,
    // 				Elevation: 0,
    // 				Distance: 0,
    // 				LinkCode: data.linkCode,//equip.linkCode,
    // 				bound: data.bound,//equip.bound,
    // 				tech: "R",
    // 				test: 'real'
    // 			}
    // 			var socket = me.socket;
    // 			var listenerList = socket.listeners["event"];
    // 			for (var i = 0; i < listenerList.length; i++) {
    // 				var listener = listenerList[i];
    // 				if (!listener.func instanceof Function) continue;
    // 				try {
    // 					listener.func(eventInfo);
    // 				} catch(e) {
    // 					console.log(e);
    // 				}
    // 			}
    // 		// },refreshTime);
    // 	}, 100)
    // },

    demoEvent: function () {
      var me = this;
      var tempArr = [
        {
          video:
            "http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16052279.STOP.287m",
          img: "http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16052279.STOP.287m.jpg",
        },
        {
          video:
            "http://211.229.157.247:9079/smart-ISD/record?date=2020-12-07&event=16051127.STOP.41m",
          img: "http://211.229.157.247:9079/smart-ISD/record?date=2020-12-07&event=16051127.STOP.41m.jpg",
        },
        {
          video:
            "http://211.229.157.247:9079/smart-ISD/record?date=2020-12-07&event=16045930.STOP.33m",
          img: "http://211.229.157.247:9079/smart-ISD/record?date=2020-12-07&event=16045930.STOP.33m.jpg",
        },
        {
          video:
            "http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16044467.PEDESTRIAN.83m",
          img: "http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16044467.PEDESTRIAN.83m.jpg",
        },
        {
          video:
            "http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16035741.PEDESTRIAN.123m",
          img: "http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16035741.PEDESTRIAN.123m.jpg",
        },
        {
          video:
            "http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16031004.PEDESTRIAN.58m",
          img: "http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16031004.PEDESTRIAN.58m.jpg",
        },
        {
          video:
            "http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16030556.PEDESTRIAN.89m",
          img: "http://211.224.164.237:9079/smart-ISD/record?date=2020-12-07&event=16030556.PEDESTRIAN.89m.jpg",
        },
      ];

      // 30초 마다 데모 이벤트 실행
      me.demoEventTimer = setTimeout(() => {
        console.log("======== demoEventTimer Start =========");
        var tempUrl = tempArr[Math.floor(Math.random() * tempArr.length)];

        // var equip = me.equipList[Math.floor(Math.random() * me.equipList.length)];
        var date = new Date();

        // Get individual date components
        // var year = date.getFullYear();
        // var month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero based
        // var day = ("0" + date.getDate()).slice(-2);
        // var hours = ("0" + date.getHours()).slice(-2);
        // var minutes = ("0" + date.getMinutes()).slice(-2);
        // var seconds = ("0" + date.getSeconds()).slice(-2);

        // 현재 시각 기준 Format the date as 'yyyyMMddHHmmss'
        // var formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;

        const options = {
          // timeZone: "Asia/Jakarta", // Jakarta는 WIB 시간대에 속함
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          weekday: "long",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false, // 24시간 형식
        };

        // Intl.DateTimeFormat을 사용하여 포맷팅
        const intlFormatter = new Intl.DateTimeFormat("id-ID", options);
        const formattedParts = intlFormatter.formatToParts(date);

        // 시, 분, 초를 개별적으로 추출
        let year, month, day, weekday, hours, minutes, seconds;
        formattedParts.forEach((part) => {
          if (part.type === "year") {
            year = part.value;
          } else if (part.type === "month") {
            month = part.value;
          } else if (part.type === "day") {
            day = part.value;
          } else if (part.type === "weekday") {
            weekday = part.value;
          } else if (part.type === "hour") {
            hours = part.value;
          } else if (part.type === "minute") {
            minutes = part.value;
          } else if (part.type === "second") {
            seconds = part.value;
          }
        });

        me.currentDate = `${year}${month}${day}${hours}${minutes}${seconds}`;

        var formattedDate = me.currentDate;
        var equipList = me.equipList;
        // var equipListNumRandom = Math.floor(Math.random() * filteredItems.length);
        var equipListNumRandom = Math.floor(Math.random() * equipList.length);
        var randomIndex;
        // const rcEventType = ["1", "2", "3", "4", "9"];
        const rcEventType = [
          { type: "1", weight: 22 }, // 정지차
          //{ type: "2", weight: 1 }, // 보행자
          //{ type: "3", weight: 1 }, // 역주행
          { type: "4", weight: 32 }, // 급정거
          { type: "9", weight: 44 }, // 지정체
        ];
        let eventType = this.getRandomEvent(rcEventType);
        let equipType = this.getRandomEquip(equipList);

        // const sEventType = ["11", "12", "13", "14"];
        // var eventType;
        var techNm;

        if (
          // equipList[equipListNumRandom].tech == "R" ||
          // equipList[equipListNumRandom].tech == "C"
          equipType.tech == "R" ||
          equipType.tech == "C"
        ) {
          // randomIndex = Math.floor(Math.random() * rcEventType.length);

          // eventType = rcEventType[randomIndex];
          // if (equipList[equipListNumRandom].tech == "R") {
          if (equipType.tech == "R") {
            techNm = me.$t("label.L0029");
          }
          // if (equipList[equipListNumRandom].tech == "C") {
          if (equipType.tech == "C") {
            techNm = me.$t("label.L0151");
          }
        }

        // if (equipList[equipListNumRandom].tech == "S") {
        //   randomIndex = Math.floor(Math.random() * sEventType.length);
        //   eventType = sEventType[randomIndex];
        //   techNm = me.$t("label.L0030");
        // }

        // const equipPosition = equipList[equipListNumRandom].position;
        //const equipPosition = equipType.position;
        //const demoPositionData = me.demoPosition(equipPosition);
        let demoPositionData = 0;

        if (equipType.bound == 0) {
          demoPositionData = equipType.position + 0.02;
        } else {
          demoPositionData = equipType.position - 0.02;
        }

        var eventInfo = {
          OccurTime: formattedDate,
          // CameraID: equipList[equipListNumRandom].cameraId, //equip.cameraId.trim(),
          CameraID: equipType.cameraId, //equip.cameraId.trim(),
          DEventType: eventType,
          RecVideoUrl: tempUrl.video,
          RecImageUrl: tempUrl.img,
          E_Longitude: 0,
          E_Latitude: 0,
          Position: demoPositionData,
          Elevation: 0,
          Distance: 0,
          // LinkCode: equipList[equipListNumRandom].linkCode, //equip.linkCode,
          // bound: equipList[equipListNumRandom].bound, //equip.bound,
          // tech: equipList[equipListNumRandom].tech,
          LinkCode: equipType.linkCode, //equip.linkCode,
          bound: equipType.bound, //equip.bound,
          tech: equipType.tech,
          techNm: techNm,
          test: "dev",
        };

        // var socket = me.socket;
        // var listenerList = socket.listeners["demoEvent"];
        // for (var i = 0; i < listenerList.length; i++) {
        //   var listener = listenerList[i];
        //   if (!listener.func instanceof Function) continue;
        //   try {
        //     listener.func(eventInfo);
        //   } catch (e) {
        //     console.log(e);
        //   }
        // }

        EventBus.$emit("demoEvent", eventInfo);

        me.demoEvent();
      }, 60000);
    },
    // 랜덤 돌발 이벤트를 선택하는 함수
    getRandomEvent(rcEventType) {
      const totalWeight = rcEventType.reduce(
        (sum, event) => sum + event.weight,
        0
      );

      const randomNum = Math.random() * totalWeight; // 0 이상 totalWeight 미만의 난수
      let weightSum = 0;

      for (let event of rcEventType) {
        weightSum += event.weight;
        if (randomNum < weightSum) {
          return event.type;
        }
      }
    },
    // 타입에 따라 아이템 선택
    getRandomEquip(type) {
      const totalWeight = type.reduce((sum, equip) => sum + equip.weight, 0);

      const randomNum = Math.random() * totalWeight; // 0 이상 totalWeight 미만의 난수
      let weightSum = 0;

      for (let equip of type) {
        weightSum += equip.weight;
        if (randomNum < weightSum) {
          return equip;
        }
      }
    },
    demoPosition(equipPosition) {
      // Generate a random number between -0.05 and +0.05
      var demoPositionRandom = 0;
      if (equipPosition <= 0.05) {
        demoPositionRandom = Math.random() * 0.1 + 0.05; // 0 to 0.1, then shift by -0.05
      } else {
        demoPositionRandom = Math.random() * 0.1 - 0.05; // 0 to 0.1, then shift by -0.05
      }
      const demoPositionData = equipPosition + demoPositionRandom;

      return demoPositionData;
    },
    async getActvieScheduleList() {
      var vue = this;
      try {
        const res = await this.api.getActiveSchedule({
          tunnelCode: this.selectTunnelCode,
        });
        const list = Array.from(res.data.data.scheduleList);

        var oldScheduleList = vue.scheduleList; // oldScheduleList: 현재까지 화면에 그려져 있는 일정
        vue.scheduleList = [];
        vue.scheduleList = list.filter((schedule) => {
          // 현재 진행중인 공사일정만 필터링
          // 읽어온 일정의 공사일정 Date 타입으로 변환
          const startDate = new Date(
            schedule.startDate + " " + schedule.startTime
          );
          const endDate = new Date(schedule.endDate + " " + schedule.endTime);
          const date = new Date(); // 현재 일자 설정
          return startDate <= date && endDate >= date;
        });

        for (var i = 0; i < oldScheduleList.length; i++) {
          var oldSchedule = oldScheduleList[i];
          var find = false;
          for (var j = 0; j < vue.scheduleList.length; j++) {
            if (oldSchedule.no == vue.scheduleList[j].no) {
              // 이미 그려져 있는 일정인 경우, 방면 & 이정의 변동이 없어야 삭제하지 않음
              if (
                oldSchedule.bound == vue.scheduleList[j].bound &&
                oldSchedule.startPosition ==
                  vue.scheduleList[j].startPosition &&
                oldSchedule.endPosition == vue.scheduleList[j].endPosition
              ) {
                find = true;
              }
              break;
            }
          }
          // 그려준 이정 element가 삭제 되어야 하는 경우
          // 1. 일정자체가 종료되었을 때
          // 2. 방면, 이정 등의 변동이 생겼을 때
          if (!find) {
            vue.removeConstruction(oldSchedule);
          }
        }
        await setTimeout(() => {
          this.underConstruction();
        }, 1000);
      } catch (err) {
        console.log("getActvieScheduleList() Error ... ", err);
      }
    },
    activeScheduleTimer: function () {
      this.getActvieScheduleList();
      this.scheduleTimer = setTimeout(() => {
        this.activeScheduleTimer();
      }, 60 * 1000); // 1분마다 공사일정 그리기
    },
    findSchedule: function (position, bound) {
      var date = new Date();
      var scheduleList = this.scheduleList;
      var deleteScheduleList = [];
      var currentTime = date.getTime();
      var result = false;

      if (scheduleList == null || scheduleList.length == 0) return false;

      // 공사일정 리스트 확인 로직
      for (var i = 0; i < scheduleList.length; i++) {
        var schedule = scheduleList[i];

        // 공사일정과 같지 않은 방면정보라면 {{ $t("button.B0009") }} 공사일정 확인
        if (schedule.bound != bound) continue;

        // 공사일정과 같은 방면일경우 로직 실행
        var endTimeStr = schedule.endTime.trim().split(":");
        var endDate = new Date(schedule.endDate.trim());
        endDate.setHours(endTimeStr[0], endTimeStr[1], 0);

        // 공사가 끝났을 경우 실행
        if (endDate.getTime() < currentTime) {
          deleteScheduleList.push(i);
          continue;
        }

        // ttestst 2222 33333

        var startTime = new Date(date);
        var endTime = new Date(date);
        var startTimeStr = schedule.startTime.trim().split(":");

        startTime.setHours(startTimeStr[0], startTimeStr[1], 0);
        endTime.setHours(endTimeStr[0], endTimeStr[1], 0);

        if (
          !(
            startTime.getTime() <= currentTime &&
            endTime.getTime() >= currentTime
          )
        ) {
          continue;
        }

        var startPosition = parseFloat(schedule.startPosition);
        var endPosition = parseFloat(schedule.endPosition);
        var p = parseFloat(position); // 돌발 발생 위치

        // 돌발발생의 위치가 공사중인 위치라면 실행
        if (startPosition <= p && endPosition >= p) {
          if (schedule.bound == bound)
            // 방면 추가
            result = true;
          break;
        }
      }

      // 공사가 끝난 스케쥴 리스트
      for (var i = 0; i < deleteScheduleList.length; i++) {
        scheduleList.splice(deleteScheduleList[i] - i, 1);
      }

      // 공사 일정과 겹친다면 true, 겹치지 않는다면 false
      return result;
    },

    findEquipBound: function (cameraId) {
      var me = this;
      var result = 0;
      var equipList = me.equipList;

      for (var i = 0; equipList.length; i++) {
        if (equipList[i].cameraId == cameraId) {
          result = equipList[i].bound;
          break;
        }
      }
      return Number(result);
    },

    async initTunnel() {
      // jyp 터널별 탭 기능에 필요한 정보 조회한다.
      const selectTunnelTabInfoList = await this.api.selectTunnelTabInfoList();

      // jyp 터널별 탭 기능에 필요한 데이터만 담기 위한 상수 배열을 선언한다.
      const tunnelTabDataArr = [];

      // jyp data 상수에 터널별 탭 기능에 필요한 정보 조회한 데이터를 담는다.
      const { data } = selectTunnelTabInfoList.data;

      // jyp tunnelTabInfoList 상수에 data 안의 tunnelTabInfoList 변수 데이터를 담는다. tunnelTabInfoList 변수 데이터는 터널별 탭 기능에 필요한 데이터가 담아져있다.
      const tunnelTabInfoList = data.tunnelTabInfoList;

      //터널별 탭 정보 담기
      for (var i = 0; i < tunnelTabInfoList.length; i++) {
        const data = tunnelTabInfoList[i];
        // jyp tunnelTabData 배열안에 대시보드에서 기능에 필요한 터널별 데이터를 담는다.
        const tunnelTabData = {
          bound: data.bound, // 0
          lanecnt: data.lanecnt, // 3
          code: data.code, // "SBT"
          direction: data.direction, // "수락방음터널-하행"
          startposition: data.startp, // 0
          endposition: data.endp, // 150
        };
        tunnelTabDataArr.push(tunnelTabData);
      }

      // jyp code 기준으로 그룹화 하여 데이터 담기 위한 배열을 선언한다.
      const tunnelTabGroupCode = {};

      // jyp tunnelTabDataArr 리스트만큼 돌면서 code가 같은 배열끼리 그룹화 한다.
      tunnelTabDataArr.forEach((obj) => {
        const key = obj.code; //그룹 기준이 될 속성

        // jyp code가 다른 경우 tunnelTabGroupCode 배열안에 새로 배열을 만든다.
        if (!tunnelTabGroupCode[key]) {
          tunnelTabGroupCode[key] = [obj];
        } else {
          // jyp code가 같을 경우 같은 code 배열안에 추가한다.
          tunnelTabGroupCode[key].push(obj);
        }
      });
      this.tunnelTapInfo = tunnelTabGroupCode;
      // jyp
      this.roadMap.initTunnel(tunnelTabGroupCode);
    },
    async getAllEquipList() {
      var me = this;
      try {
        const response = await this.api.getAllEquipList();
        const { data } = response.data;
        me.allEquipList = data.allEquipList;
      } catch (err) {}
    },
    resize: function (target) {
      const elelist = document.getElementsByClassName("underConstruction-box");

      if (target.innerHeight >= 1079) {
        this.isFullScreen = true;
        for (let index = 0; index < elelist.length; index++) {
          const element = elelist[index];
          if (element.className.indexOf("up") !== -1) {
            element.className = element.className + " fullScreenUp";
          } else {
            element.className = element.className + " fullScreenDown";
          }
        }
      } else {
        this.isFullScreen = false;
        for (let index = 0; index < elelist.length; index++) {
          const element = elelist[index];
          if (element.className.indexOf(" fullScreenUp") !== -1) {
            element.className = element.className.replace(/fullScreenUp/g, "");
          } else {
            element.className = element.className.replace(
              /fullScreenDown/g,
              ""
            );
          }
        }
      }
    },
  },
  created: function () {
    var me = this;
    var socket = this.socket;

    EventBus.$on("changeMenu", this.changeMenu);

    var upPort = this.selectTunnelPort.up;
    var downPort = this.selectTunnelPort.down;

    EventBus.$on("speedColorSetting", (stagnation, delay, slow, smooth) => {
      this.stagnation = Number(stagnation);
      this.delay = Number(delay);
      this.slow = Number(slow);
      this.smooth = Number(smooth);
      me.roadMap.settingVehicleColor(
        this.stagnation,
        this.delay,
        this.slow,
        this.smooth
      );
    });

    //모든 터널 장비리스트
    me.getAllEquipList();

    // state.vue에서 터널코드 값을 받아와 세팅 후 api 호출
    EventBus.$on("selectTunnelCode", (tunnelCode, port) => {
      var portSplit = port.split(",");
      upPort = portSplit[0];
      downPort = portSplit[1];

      var scheduleGroup = document.querySelector(".scheduleGroup");
      if (scheduleGroup != undefined) {
        // 해당 요소의 모든 자식 요소를 삭제합니다.
        while (scheduleGroup.firstChild) {
          scheduleGroup.removeChild(scheduleGroup.firstChild);
        }
      }

      me.roadMap.removeVehicle();
      this.selectTunnelCode = tunnelCode;
      me.activeScheduleTimer();
      // me.getEquipList(tunnelCode);

      setTimeout(function () {
        me.demoData(tunnelCode);
      }, 100);
      // me.getTunnelVehile(val);
    });

    // 터널탭 변경시 호출
    EventBus.$on("selectTunnelEndposition", (endposition) => {
      this.endposition = endposition;
    });

    me.activeScheduleTimer();

    // 돌발{{ $t("label.L0044") }} 발생
    // socket.addListener(this, "event", function (data) {
    // 	// console.log("data:::",data.CameraID);
    // 	var me = this;
    // 	var bound = me.findEquipBound(data.CameraID); // 방면 ( number )
    // 	if (me.findSchedule(data.Position,bound)) return;
    // 	if (me.isShow) {
    // 		me.roadMap.occurEvent(data);
    // 	} else {
    // 		me.eventQueue.push(data);
    // 		if (me.eventQueue.length > 5) me.eventQueue.shift();
    // 	}
    // });

    // socket.addListener(this, "wrongSoundInfo", function (data) {
    //   var roadData = {};
    //   for (var i = 0; i < me.soundDeviceList.length; ++i) {
    //     if (me.soundDeviceList[i].deviceId === data.deviceId.trim()) {
    //       roadData = me.soundDeviceList[i];
    //     }
    //   }
    //   me.roadMap.occurEvent(roadData);
    //   //me.roadMap.updateVehicle(data.bound, data.vehicleList);
    // });

    // socket.addListener(this, "vehicleInfo", function (data) {
    //   // console.log("data[0].port:::",data[0].port);
    //   // console.log("upPort:::",upPort);
    //   // console.log("data[1].port:::",data[1].port);
    //   // console.log("downPort:::",downPort);
    //   if (data[0].port == upPort) {
    //     me.roadMap.updateVehicle(data[0].bound, data[0].vehicleList);
    //     // console.log(data[0].vehicleList);
    //   }
    //   if (data[1].port == downPort) {
    //     me.roadMap.updateVehicle(data[1].bound, data[1].vehicleList);
    //     // console.log(data[1].vehicleList);
    //   }
    // });

    // socket.addListener(this, "vehicletest", function (data) {
    //   me.roadMap.updateVehicle(data.bound, data.vehicleList);
    //   // if (me.timesocket) {
    //   // 	clearInterval(me.timesocket);
    //   // }
    //   // setTimeout(function(){
    //   // 	me.demoData(Number(data.count));
    //   // }, 100);
    // });

    // socket.addListener(this, "testevent", function (data) {
    //   var socket = me.socket;
    //   var listenerList = socket.listeners["event"];
    //   for (var i = 0; i < listenerList.length; i++) {
    //     var listener = listenerList[i];
    //     if (!listener.func instanceof Function) continue;
    //     try {
    //       listener.func(data);
    //     } catch (e) {
    //       //console.log(e);
    //     }
    //   }
    // });

    // socket.addListener(this, "webSocketClose", function (data) {
    //   me.roadMap.removeVehicle();
    //   var boundStr = "하행 방면";
    //   if (data.bound == 1) boundStr = "상행 방면";
    //   //me.msgBox(boundStr + " 레이더와 연결이 끊겼습니다.");
    // });

    // socket.addListener(this, "status", function (data) {
    //   var tmp = {};
    //   me.equipList.forEach((each) => {
    //     tmp[each.cameraId] = each.bound;
    //   });
    //   var statusList = data.StatusList;
    //   for (var i = 0; i < statusList.length; i++) {
    //     var equip = statusList[i];
    //     me.roadMap.updateEquipState(equip, tmp);
    //   }
    // });

    // socket.addListener(this, "updateSchedule", function (data) {
    //   console.log("111");
    //   me.getActvieScheduleList();
    // });

    this.toggleSub = getToggle().subscribe((value) => {
      this.toggle = value;
      this.underConstruction(true);
    });
    this.initTunnel();

    //돌발아이콘 {{ $t("label.L0044") }} 버스 받는다.
    EventBus.$on("occurEvent", (data, tunnelCode, popupCancleEventList) => {
      const eventEquipList = this.allEquipList.filter(
        (obj) => obj.siteid === tunnelCode
      );
      var me = this;
      try {
        if (me.isShow) {
          var roadMap = me.roadMap;
          roadMap.eventEquipList = eventEquipList;
          // if (popupCancleEventList.includes(data.DEventType)) {
          //   // 알람 표출 안함
          //   return;
          // }
          roadMap.occurEvent(data);
        } else {
          me.eventQueue.push(data);
          if (me.eventQueue.length > 5) me.eventQueue.shift();
        }
      } catch (err) {}
    });

    EventBus.$on("demoEventPopupYn", (val) => {
      const demoEventPopupYn = val;
      if (demoEventPopupYn == false) {
        console.log("Alram Off");
        if (this.demoEventTimer != null) {
          clearTimeout(this.demoEventTimer);
          this.demoEventTimer = null;
        }
        return;
      } else if (demoEventPopupYn == true) {
        console.log("Alram On");
        this.demoEvent();
      }
    });

    // 데모 돌발{{ $t("label.L0044") }} 함수 실행
    this.demoEvent();
  },
  mounted: function () {
    var roadMap = new RoadMap({
      isJaeyaksan: true,
      code: "WYR",
      tooltip: {
        class: "equip-tooltip",
        data: this.tooltip,
      },
    });
    this.roadMap = roadMap;

    roadMap.draw();
    roadMap.event.equipClick = function (data, index) {
      EventBus.$emit("equipClick", data, index);
    };

    EventBus.$on("changeCamera", function (id, code) {
      // if (code == "NST") {
      roadMap.viewCamera(id);
      // }
    });

    var me = this;
    setTimeout(() => {
      this.resize(window);
    }, 500);

    window.addEventListener("resize", (value) => {
      this.resize(value.target);
    });

    // setTimeout(function(){
    // 	me.demoData();
    // },100);
  },
  updated: function () {
    if (this.isShow) {
      var roadMap = this.roadMap;
      roadMap.isShow = true;
      if (!roadMap.isRender) {
        roadMap.draw();
        this.getEquipList(this.selectTunnelCode);
      } else {
        roadMap.redraw();
      }
    }
  },
  beforeDestroy: function () {
    // {{ $t("label.L0044") }} 리스너를 해제하지 않으면 메뉴 이동 때마다 중복 발생
    // this.socket.deleteListener(this);
    EventBus.$off("tunnelClick");
    EventBus.$off("changeMenu");
    this.roadMap.destroy();
    if (this.scheduleTimer != null) {
      clearTimeout(this.scheduleTimer);
    }
    if (this.toggleSub != null) {
      this.toggleSub.unsubscribe();
    }
    if (this.demoEventTimer != null) {
      clearTimeout(this.demoEventTimer);
    }
  },
};
</script>

<style>
.dashboard_rtd_panel {
  height: 100%;
  width: 100%;
}
.rtd_div {
  height: 100%;
  width: 100%;
}
.dashboard_rtd_panel #roadMap {
  display: flex;
  justify-content: flex-end;
  text-align: left;
}
.dashboard_rtd_panel .map-button-group {
  position: fixed;
  display: flex;
  flex-direction: column;
}
.dashboard_rtd_panel .map-button-group .map-btn {
  background-color: #1c345d;
  margin-bottom: 10px;
}
.dashboard_rtd_panel .map-button-group .map-btn:hover {
  background-color: #3e64ff;
}
.radarImg .testsvg .testtest {
  fill: blue;
}
.radarImg .testsvg .testtest2 {
  fill: green;
}
#roadMap .equip-tooltip {
  position: absolute;
  text-align: center;
  width: 90px;
  height: 30px;
  padding: 2px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border-width: 1px;
  border-style: solid;
  border-color: steelblue;
  border-radius: 8px;
  pointer-events: none;
  opacity: 0;
}
.underConstruction-box {
  text-align: center;
  width: 10px;
  background: url("../../../../assets/diagonal-line.svg") repeat-x;
  position: absolute;
  height: 56px;
}
/* .fullScreenUp {
		top: 318px !important;
	}
	.fullScreenDown {
		top: 404px !important
	} */
.up {
  top: 36.78%;
}
.down {
  bottom: 47%;
}
.img {
  margin-left: 50%;
  margin-top: 15px;
  width: 30px;
  height: 26px;
  background: url("../../../../assets/construction.svg");
  transform: translateX(-50%);
}
.tooltip-box {
  background: #181d2c;
  border-radius: 0.325em;
  position: absolute;
  z-index: 15;
  display: flex;
  padding: 3px 10px;
  visibility: hidden;
  min-width: max-content;
}
.tooltip-box .icon {
  background: rgb(249, 213, 61);
  width: 24px;
  height: 24px;
  margin-top: 10px;
  /* margin-left: 19px; */
  text-align: center;
  border-radius: 24px;
}
.tooltip-box .icon img {
  width: 15px;
  height: 15px;
  vertical-align: unset !important;
  margin-top: 4px;
}
.tooltip-box .text {
  font-size: 12px;
  margin-top: 3px;
  margin-left: 15px;
  color: #e2e2e2;
}
.up .tooltip-box {
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
}
.down .tooltip-box {
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
}
</style>
