<template>
	<div class="dashboard_rtc_panel" style="height:100%; width:100%">
		<div style="height:100%; width:100%">
			<!-- <div style="width:100%; height: 16%; display: flex; align-items: center; padding-top: 1rem; padding-left: 1rem; padding-right: 1rem;"> -->
			<div style="width:100%; height: 16%; display: flex; align-items: center; padding-left: 1rem; padding-right: 1rem;">
				<div style="height: 22px; margin-right: 10px;">
					<img src="resources/img/icons/dashboard/cctv/n_5_on.svg" height="20px"/>
				</div>
				<!-- <div style="font-size: 22px; float:left; color:#FFFFFF; flex: 1; font-weight: 600;"> -->
				<div style="flex: 1; font-weight: 600; color:#FFFFFF;">
					CCTV
				</div>
				<div class="cctv-layout-btn-group" style="float:right;">
					<img height="16px;"  v-bind:src="btnSrcBase+cctvMode1Src" v-on:click="changeCCTVMode(1)" style="padding-right: 10px"/>
					<svg class="Line" width="2px" height="17px">
						<rect id="Line" width="2px" height="17px" fill="#B7B7B7"></rect>
					</svg>
					<!-- <img v-bind:src="btnSrcBase+cctvMode2Src" v-on:click="changeCCTVMode(2)"/>  -->
					<img height="16px" v-bind:src="btnSrcBase+cctvMode3Src" v-on:click="changeCCTVMode(3)" style="padding-left: 10px"/>
					<!-- <img v-bind:src="btnSrcBase+cctvMode4Src" v-on:click="changeCCTVMode(4)"/>  -->
				</div>
			</div>
			<b-container fluid class="radar_container" style="height:84%;">
				<b-row class="radar_row" style="display: flex; flex-direction: row; justify-content: center; align-items: center; padding: 0.3rem">
					<div class="main_radar">
						<video style="background-color:black" id="mainVideo" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
						<div class="radar_overlay_video"></div>
					</div>
					<div class="main_radar" v-bind:class="{ 'divisionCCTV': cctvMode === 3 || cctvMode === 4 }">
						<template v-if="cctvMode === 3">
							<span style="padding-right: 0.3rem;">
								<video style="background-color:black" id="upVideo1" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span>
								<video style="background-color:black" id="upVideo2" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span style="padding-right: 0.3rem;">
								<video style="background-color:black" id="upVideo3" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span>
								<video style="background-color:black" id="upVideo4" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
						</template>
						<template v-if="cctvMode === 4">
							<span>
								<video style="background-color:black" id="upVideo5" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span>
								<video style="background-color:black" id="upVideo6" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span>
								<video style="background-color:black" id="upVideo7" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span>
								<video style="background-color:black" id="upVideo8" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
						</template>
						<template v-if="cctvMode === 1">
							<video style="background-color:black" id="upVideo" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
							<div class="radar_overlay_video"></div>
						</template>
					</div>
					<div class="main_radar" v-bind:class="{ 'divisionCCTV': cctvMode === 3 || cctvMode === 4 }">
						<template v-if="cctvMode === 3">
							<span style="padding-right: 0.3rem;">
								<video style="background-color:black" id="downVideo1" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span>
								<video style="background-color:black" id="downVideo2" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span style="padding-right: 0.3rem;">
								<video style="background-color:black" id="downVideo3" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span>
								<video style="background-color:black" id="downVideo4" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
						</template>
						<template v-if="cctvMode === 4">
							<span>
								<video style="background-color:black" id="downVideo5" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span>
								<video style="background-color:black" id="downVideo6" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span>
								<video style="background-color:black" id="downVideo7" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
							<span>
								<video style="background-color:black" id="downVideo8" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
								<div class="radar_overlay_video"></div>
							</span>
						</template>
						<template v-if="cctvMode === 1">
							<video style="background-color:black" id="downVideo" width="100%" height="100%" autoplay loop playsinline muted @click="expansion"></video>
							<div class="radar_overlay_video"></div>
						</template>
					</div>
				</b-row>
			</b-container>
		</div>
	</div>
</template>

<script>
	import { UnrealWebRTCPlayer } from '@/components/media/unrealwebrtcplayer.js'
	import { BContainer, BRow} from "bootstrap-vue";
	import EventBus from '@/event-bus'
	import store from "@/store";

	export default {
		name: "RealTimeCctvRadar",
		components: {
			BContainer, BRow
		},
		data: function() {
			return {
				selectTunnelCode: null,
				selectTunnelStartLinkCode: null,
				cameraList: null,
				cameraMap: null,
				player: {},
				// Unreal 서버 정보
				mediaServerInfo: {
					ip: "192.168.11.202",
					// ip: "192.200.3.132",
					port: "5119",
				},
				currentCameraId: "",
				isShow: false,
				btnSrcBase: 'resources/img/icons/dashboard/cctv/',
				cctvMode: 1,
				cctvMode1Src: 'n_1_on.svg',
				cctvMode2Src: 'cctv2_off.svg',
				cctvMode3Src: 'n_3_off.svg',
				cctvMode4Src: 'cctv4_off.svg',
				upBound: 1,
				closestCameraId: null,
				closestLinkCode: null,
			}
		},
		methods: {
				changeCCTVMode: function (sw) {
				this.cctvMode = sw;
				switch (sw) {
					case 1:
						this.expansionClose();
						this.cctvMode1Src='n_1_on.svg';
						this.cctvMode2Src='cctv2_off.svg';
						this.cctvMode3Src='n_3_off.svg';
						this.cctvMode4Src='cctv4_off.svg';
						break;
					case 2:
						this.expansionClose();
						this.cctvMode1Src='n_1_off.svg';
						this.cctvMode2Src='cctv2_on.svg';
						this.cctvMode3Src='n_3_off.svg';
						this.cctvMode4Src='cctv4_off.svg';
						break;
					case 3:
						this.expansionClose();
						this.cctvMode1Src='n_1_off.svg';
						this.cctvMode2Src='cctv2_off.svg';
						this.cctvMode3Src='n_3_on.svg';
						this.cctvMode4Src='cctv4_off.svg';
						break;
					case 4:
						this.expansionClose();
						this.cctvMode1Src='n_1_off.svg';
						this.cctvMode2Src='cctv2_off.svg';
						this.cctvMode3Src='n_3_off.svg';
						this.cctvMode4Src='cctv4_on.svg';
					break;
				}
			},
			changeTunnel: function(data) {
				this.isShow = true;

				if(this.cameraMap == null) this.getCameraList();
				else this.changeCamera(this.currentCameraId, data.code, true, '');
				var me = this;
			},
			equipClick: function(data, index) {
				var linkCode = data.linkCode;
				var code = linkCode.substring(linkCode.length-3,linkCode.length);
				this.changeCamera(data.cameraId, code, '', '');
			},
			changeCamera: function (id, code, isChangeTunnel, position) {	
				if (id == "") return;
				if (!isChangeTunnel && this.currentCameraId == id) return;

				var playerList = this.player;
				var index = this.cameraMap[id].index;
				var cameraList = null;
				let boundFolderName = 'down'; 

				if (this.cameraMap[id].bound == 0) {
					cameraList = this.cameraList[this.cameraMap[id].link_code];
					boundFolderName = 'down'
				} else if (this.cameraMap[id].bound == 1) {
					cameraList = this.cameraList[this.cameraMap[id].link_code];
					boundFolderName = 'up'
				}
				this.currentCameraId = id;
				
				// route.json파일 -> 대시보드 도로 길이 정보 
				// var getLoadConfigJson = this.$store.getters["settingConfig/getTarget"]("loadConfigJson");
				// var endPosition = getLoadConfigJson.endPosition;

				if (index == null) return;

				this.stopCamera();
				var mainCamera = cameraList[index];
				var upIndex = 1;
				if (mainCamera.bound != this.upBound) upIndex = -1;
		
				var cameraInfoArr = [];
				if (this.cctvMode === 1) {
					cameraInfoArr.push({ camera: mainCamera, id: "upVideo", key: "new" });
					for (var i = index + 1; i < cameraList.length; i++) {
						const data=cameraList[i];
						if (!!data) {
							cameraInfoArr.push({ camera : data, id : "downVideo", key:"up"} );
							break;
						}
					}
					for (var i = index-1; i >= 0; i--) {
						const data=cameraList[i];					
						if (!!data) {
							cameraInfoArr.push({ camera : data, id : "mainVideo", key:"down"} );
							break;
						}
					}
				} else {
					cameraInfoArr.push({ camera : mainCamera, id : "mainVideo", key:"new"});
					var count = 1;
					for (var i = index + 1; i < cameraList.length; i++) {
						const data = cameraList[i];
						if (!!data) {
							cameraInfoArr.push({ camera : data, id : "downVideo"+count, key:"up"});
							count++;
							if (count == 5) {
								break;
							}
						}
					}
					count = 1;
					for (var i = index - 1; i >= 0; i--) {
						const data=cameraList[i];
						if (!!data) {
							cameraInfoArr.push({ camera : data, id : "upVideo"+count, key:"down"});
							count++;
							if (count == 5) {
								break;
							}
						}
					}
				}

				// let randomVideoIndexArr = null;
				// randomVideoIndexArr = [0,1,2,3,4,5,6,7,8];


				// for (let i = 0 ; i < randomVideoIndexArr.length; i++) {
				// 	const j = Math.floor(Math.random() * (randomVideoIndexArr.length));
				// 	[randomVideoIndexArr[i], randomVideoIndexArr[j]] = [randomVideoIndexArr[j], randomVideoIndexArr[i]];
				// }

				for (var i = 0; i < cameraInfoArr.length; i++) {
					// var randomVideoIndex = Math.floor(Math.random() * cameraInfoArr.length)
					
					var elementId = cameraInfoArr[i].id;
					var cameraInfo = cameraInfoArr[i].camera;
					var element = document.getElementById(elementId);

					if (!!element && !!cameraInfo) {
						element.src = require('@/assets/video/' + boundFolderName + '/' + cameraInfo.section + '.mp4'); // MP4 영상 파일 경로
						// element.src = require('@/assets/video/' + boundFolderName + '/' + cameraInfo.rtsp_url + '.mp4'); // MP4 영상 파일 경로
						// element.src = require('@/assets/video/sample'+ randomVideoIndexArr[i] + '.mp4'); // MP4 영상 파일 경로
						// element.src = 'resources/video/sample'+ randomVideoIndexArr[i] + '.mp4'; // MP4 영상 파일 경로
						// playerList[elementId].type = 'video/mp4';

						// playerList[elementId] = new UnrealWebRTCPlayer(elementId, cameraInfo.rtsp_alias, "", this.mediaServerInfo.ip, this.mediaServerInfo.port, false, true, "tcp");
						// playerList[elementId].Play();
						var label = "" + cameraInfo.name;
						element.parentNode.lastChild.textContent = label;
					}
				}
				EventBus.$emit("changeCamera",id, code, '', '');
			},
			stopCamera: function() {
				var playerList = this.player;

				for (var key in playerList) {
					if (playerList[key] != null) {
						playerList[key].Stop();
						playerList[key] = null;
					}
				}
				var idArr;
				if (this.cctvMode == 1) {
					idArr = ["mainVideo", "upVideo", "downVideo"];
				}
				else if (this.cctvMode == 3) {
					idArr = ["mainVideo", "upVideo1", "upVideo2","upVideo3","upVideo4","downVideo1","downVideo2","downVideo3","downVideo4"];
				}
				else if (this.cctvMode == 4) {
					idArr = ["mainVideo", "upVideo5", "upVideo6","upVideo7","upVideo8","downVideo5","downVideo6","downVideo7","downVideo8"];
				}

				for (var i = 0; i < idArr.length; i++) {
					var id = idArr[i];
					var videoElement = document.getElementById(id);
					if (!!videoElement) {
						videoElement.removeAttribute('src');
						videoElement.srcObject = null;
						videoElement.parentNode.lastChild.textContent="";
						videoElement.load();
					}
				}
			},
			async getCameraList() {
				var me = this;
				var params = {
					tunnelCode: this.selectTunnelCode
				};
				try {
					const response = await this.api.getTunnelCameraList(params);
					var sortCameraList = {};
					var cameraMap = {};
					var cameraList = [...response.data.cameraList];

					for (var i = 0; i < cameraList.length; i++) {
						var cameraInfo = cameraList[i];
						// sortCameraList에 현재 카메라 인포의 link_code 값이 없으면 빈 항목 추가
						if (sortCameraList[cameraInfo.link_code] == null) sortCameraList[cameraInfo.link_code] = [];
						var list = sortCameraList[cameraInfo.link_code];

						cameraMap[cameraInfo.camera_id] = {
							bound: cameraInfo.bound,
							link_code : cameraInfo.link_code,
							index: list.length
						};
						list.push(cameraInfo);
					}
					me.cameraList = sortCameraList;
					me.cameraMap = cameraMap;
					me.changeCamera(sortCameraList[cameraList[1].link_code][1].camera_id, "MCT");
				}
				catch(err) {
				}
			},
			expansion: function (e) {
				var el = e.target;
				var parent = el.parentNode;
				parent.classList.toggle('expansion');

				var allExpansionElements = document.querySelectorAll('.expansion');
				for (var i = 0; i < allExpansionElements.length; i++) {
					if (allExpansionElements[i] !== parent) {
						allExpansionElements[i].classList.remove('expansion');
					}
				}
			},
			expansionClose: function () {
				var allExpansionElements = document.querySelectorAll('.expansion');
				for (var i = 0; i < allExpansionElements.length; i++) {
					allExpansionElements[i].classList.remove('expansion');
				}
			},
			findClosestCameraId: function (id, position, linkCode) {
				// 들어온 linkCode 값에 현재 선택된 터널코드가 포함되어 있는 경우에만 실행
				if (id.includes(this.selectTunnelCode)) {
					this.api.getClosestCameraId({
						id: id, // 장비 아이디
						position: position, //이정
					}).then((res) => {
						var data = res.data;
						this.closestCameraId = data.data.findClosestCameraId[0].camera_id;
						this.closestLinkCode = data.data.findClosestCameraId[0].link_code;
						var code = this.closestLinkCode.substring(this.closestLinkCode.length - 3, this.closestLinkCode.length);
						this.changeCamera(this.closestCameraId, code, true, position);
					}).catch((error) => {
						console.error("Error getting closest camera ID:", error);
					});
				}
			}
		},
		updated:function() {
			this.changeCamera(this.currentCameraId, '', true, '');
		},
		created : function() {
			var me = this;
			var socket = this.socket;

			this.api.getMediaServerConfig()
			.then(function(res) {
				var data =  res.data;
				me.mediaServerInfo.ip = data.data["ip"] ;
				me.mediaServerInfo.port = data.data["port"] ;
			});

			EventBus.$on('occurEvent', function (data) {
				// 돌발 데이터에서 받은 카메라 아이디, 이정을 보낸다.
				me.findClosestCameraId(data.CameraID, data.Position, data.LinkCode);
			});

			EventBus.$on('selectTunnelCode', (val) => {
				this.selectTunnelCode = val;
				this.getCameraList();
			});

			EventBus.$on('selectTunnelStartLinkCode', (val) => {
				this.selectTunnelStartLinkCode = val;
				this.getCameraList();
			});
			
			EventBus.$on("tunnelClick", this.changeTunnel);
			EventBus.$on('equipClick', this.equipClick);
		},
		beforeDestroy : function() {
			// this.socket.deleteListener(this);
			// EventBus.$off('tunnelClick');
			EventBus.$off('equipClick');
			EventBus.$off('occurEvent');
			EventBus.$off('selectTunnelCode');
		}
	}
</script>
<style>
	.dashboard_rtc_panel {
		background: #2C2C2C 0% 0% no-repeat padding-box;
		/* border-radius: 20px; */
		opacity: 1;
		width: 100%;
		height: 100%;
		border: 2px solid rgb(68, 68, 68);
		border-radius: 10px;
		box-shadow: 5px 10px 22px #00000029;
	}
	.dashboard_rtc_panel .radar_container {
		height: 100%;
		width: 100%;
	}
	.dashboard_rtc_panel .radar_container .radar_row {
		height: 100%;
		width: 100%;
	}
	.div_radar_left {
		width: 40.8%;
		height: 100%;
		margin-left: auto;
	}
	.div_radar_right {
		width: 58%;
		height: 100%;
		margin-right: auto;
	}
	.container-fluid {
	padding-left: 0px !important;
	padding-right: 0px !important;
	}
	.main_radar {
		position: relative;
		width: 100%;
		height: 100%;
		padding : 0.3rem;
		flex: 1;
		display: flex;
		align-items: flex-end;
	}
	.main_radar.expansion > video {
		position: absolute;
		width: 175% !important;
		height: 175% !important;
		z-index: 15 !important;
	}
	.main_radar.expansion > .radar_overlay_video {
		width: 175% !important;
		z-index: 16 !important;
	}
	span.expansion > video {
		position: absolute;
		width: 300% !important;
		height: 300% !important;
		z-index: 15 !important;
		bottom: 0 !important;
	}
	span.expansion > .radar_overlay_video {
		width: 300% !important;
		z-index: 16 !important;
	}
	.sub_radar {
		width: 33.3%;
		height: 100%;
		padding : 1vh;
	}
	.main_radar > video {
		border-radius: 20px;
	}
	.main_radar > span > video {
		border-radius: 20px;
	}
	.main_radar > .expansion > video {
		border-radius: 20px;
	}
	.radar_overlay_video {
		position: absolute;
		color: #E1E1E1;
		border-radius: 0 0 20px 20px;
		display: flex;
		padding: 0 1rem;
		align-items: center;
		font-size: 1rem;
		overflow: hidden;
		height: 30px;
		width: calc(100% - 0.55rem);
	}
	.divisionCCTV {
		display:inline;
	}
	.divisionCCTV span {
		margin: 0 auto;
		width: calc(50% - 0.2em);
		height: calc(50% - 0.2em);
		display: inline-block;
		/* position:absolute; */
		position: relative;
	}
	.divisionCCTV span .video {
		position: absolute;
	}
	.divisionCCTV span .radar_overlay_video {
		bottom: 0;
		width: 100%;
		height: 21px;
	}
	.cctv-layout-btn-group img:hover {
		cursor: pointer;
	}
</style>
